import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import { Link } from "gatsby-theme-material-ui"
import { Typography } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    paddingBottom: theme.spacing(1),
  },
  logo: {
    margin: theme.spacing(2),
    width: theme.spacing(5),
  },
  goldBar: {
    height: 4,
    width: "100%",
    background:
      "linear-gradient(50deg, rgba(253,233,211,1) 0%, rgba(197,161,120,1) 50%, rgba(253,233,211,1) 100%)",
    position: "relative",
    top: theme.spacing(1),
    marginBottom: theme.spacing(6),
  },
  goldBar2: {
    height: 4,
    width: "100%",
    background:
      "linear-gradient(50deg, rgba(253,233,211,1) 0%, rgba(197,161,120,1) 50%, rgba(253,233,211,1) 100%)",
    marginBottom: theme.spacing(2),
  },
  icon: {
    margin: theme.spacing(0, 2),
  },
  gridItem: {
    padding: theme.spacing(3),
  },
  payment: {
    width: 100,
  },
}))

export default function Footer() {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div className={classes.goldBar2} />
      <Typography align="center" color="secondary">
        <i>&copy;</i>
        <Link to="/" color="secondary">
          {" "}
          QR STORIES{" "}
        </Link>
        {new Date().getFullYear()}
      </Typography>
    </div>
  )
}
