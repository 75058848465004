import React from "react"
import { navigate } from "gatsby"
import { auth } from "gatsby-theme-firebase"
import { useLocalization } from "gatsby-theme-i18n"
// Layout & Styling
import { makeStyles } from "@material-ui/core/styles"
import List from "@material-ui/core/List"
import Divider from "@material-ui/core/Divider"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
// Icons
import HomeIcon from "@material-ui/icons/Home"
import DashboardIcon from "@material-ui/icons/Dashboard"
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart"
import ExitToAppIcon from "@material-ui/icons/ExitToApp"
import SettingsIcon from "@material-ui/icons/Settings"

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: "auto",
    backgroundColor: theme.palette.primary.light,
  },
  title: {
    flexGrow: 1,
    fontFamily: "Lato",
  },
  listText: {
    color: theme.palette.primary.contrastText,
  },
  listIcon: {
    color: theme.palette.secondary.main,
  },
}))

export default function MenuList({ location, text }) {
  const { locale, defaultLang } = useLocalization()
  const classes = useStyles()
  const logOut = () => {
    auth.signOut()
    navigate("/")
  }
  const goTo = to => {
    navigate((locale === defaultLang ? "" : `/${locale}`) + to)
  }
  return (
    <div className={classes.root}>
      <List>
        <ListItem
          button
          selected={location === text.dashboardNavBarText}
          onClick={() => goTo("/app")}
        >
          <ListItemIcon className={classes.listIcon}>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText
            primary={text.dashboardNavBarText || "Dashboard"}
            className={classes.listText}
          />
        </ListItem>
        <ListItem
          button
          selected={location === text.cartNavBarText}
          onClick={() => goTo("/app/cart")}
        >
          <ListItemIcon className={classes.listIcon}>
            <ShoppingCartIcon />
          </ListItemIcon>
          <ListItemText
            primary={text.cartNavBarText || "Cart"}
            className={classes.listText}
          />
        </ListItem>
        <ListItem
          button
          selected={location === text.settingsNavBarText}
          onClick={() => goTo("/app/settings")}
        >
          <ListItemIcon className={classes.listIcon}>
            <SettingsIcon />
          </ListItemIcon>
          <ListItemText
            primary={text.settingsNavBarText || "Settings"}
            className={classes.listText}
          />
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListItem button onClick={() => goTo("/")}>
          <ListItemIcon className={classes.listIcon}>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText
            primary={text.navBarLandingText || "Main page"}
            className={classes.listText}
          />
        </ListItem>
        <ListItem button onClick={logOut}>
          <ListItemIcon className={classes.listIcon}>
            <ExitToAppIcon />
          </ListItemIcon>
          <ListItemText
            primary={text.navBarLogoutText || "Logout"}
            className={classes.listText}
          />
        </ListItem>
      </List>
    </div>
  )
}
