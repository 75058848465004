import React, { useEffect, useState } from "react"
// Layout
import { Container } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import List from "@material-ui/core/List"
import Divider from "@material-ui/core/Divider"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import Menu from "@material-ui/core/Menu"
import Button from "@material-ui/core/Button"
import MenuItem from "@material-ui/core/MenuItem"
// Stripe checkout
// Data
import { firestore, useFirestoreQuery } from "gatsby-theme-firebase"
import { useCookies } from "react-cookie"
import PayButton from "../payButton"
// Other
import CartItem from "./cartItem"

const useStyles = makeStyles(theme => ({
  root: {},
  list: {
    maxWidth: "80%",
    backgroundColor: theme.palette.background.paper,
    margin: theme.spacing(4, "auto"),
  },
  menu: {
    margin: theme.spacing(2),
  },
}))

export default function CartList({ user, text }) {
  const classes = useStyles()
  let total = 0
  const [cookies, removeCookie] = useCookies(["selectedOffer"])
  const [cartItems, setCartItems] = useState([])
  const [cart, isLoadingCart] = useFirestoreQuery(
    firestore.collection(`users/${user.uid}/cart`)
  )
  const [products, isLoadingProducts] = useFirestoreQuery(
    firestore.collection("products")
  )
  // Get cart Items effect
  useEffect(() => {
    let vals = []
    if (
      cart &&
      cart.length > 0 &&
      products &&
      products.length > 0 &&
      !isLoadingCart &&
      !isLoadingProducts
    ) {
      vals = cart.map(cartItem => {
        const prod = products.find(
          productsItem => productsItem._id === cartItem.product
        )
        if(!prod) return []
        const cartItemId = cartItem._id 
        const productId = prod._id
        const result = {
          ...prod,
          cartItemId,
          productId,
        }
        // delete result._id
        return result
      })
    }
    setCartItems([...vals])
  }, [cart, products, isLoadingCart, isLoadingProducts])
  // Check if there's any selectedOffer cookies and save them to the cart
  useEffect(() => {
    if (cookies.selectedOffer && products && !isLoadingProducts) {
      const cookieCart = products.find(
        prod => prod.stripePrice === cookies.selectedOffer
      )
      if (cookieCart) {
        ;(async () => {
          await firestore.collection(`users/${user.uid}/cart`).add({
            product: cookieCart._id,
          })
        })()
        removeCookie("selectedOffer")
      }
    }
  }, [products, cookies, isLoadingProducts, isLoadingCart])
  // Calculates total on cart change...
  if (!isLoadingCart && cartItems.length > 0) {
    cartItems.forEach(item => {
      total += Number(item.price)
    })
  }
  const [anchorEl, setAnchorEl] = React.useState(null)
  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }
  const selectOffer = offer => {
    setAnchorEl(null)
    const ck = products.find(prod => prod.stripePrice === offer)
    if (ck) {
      firestore.collection(`users/${user.uid}/cart`).add({
        product: ck._id,
      })
    }
  }
  // Grab data for cart items from cookie or data from landing...
  return (
    <>
      <Container className={classes.root}>
        <List className={classes.list}>
          {cartItems !== [] &&
            cartItems.map((item, i) => (
              <CartItem
                key={item.cartItemId}
                user={user}
                data={item}
                last={i === cartItems.length - 1}
              />
            ))}
          {!cartItems.length && (
            <ListItem>
              <ListItemText primary={text.cartEmptyText} align="center" />
            </ListItem>
          )}
        </List>
        <Divider />
        <div align="center" className={classes.menu}>
          <Button
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={handleClick}
            color="primary"
          >
            {text.cartAddItemText || "Ajouter un item"}
          </Button>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            {products &&
              products.map((p, i) => (
                
                <MenuItem
                // eslint-disable-next-line react/no-array-index-key
                  key={p.stripePrice + i}
                  onClick={() => selectOffer(p.stripePrice)}
                >
                  {p.name}
                </MenuItem>
              ))}
          </Menu>
        </div>
        <Divider />
        <List>
          <ListItem>
            <ListItemText
              primary={`${text.cartTotalText}:`}
              secondary={`€${total}`}
              align="right"
            />
          </ListItem>
        </List>
        <PayButton
          text={text.cartCheckoutBtn}
          user={user}
          cartItems={cartItems}
        />
      </Container>
    </>
  )
}
