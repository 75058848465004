import React, { useState } from "react"
// Layout
import {
  FormControl,
  TextField,
  MenuItem,
  Select,
  InputLabel,
  Grid,
} from "@material-ui/core"
// Data
import { firestore } from "gatsby-theme-firebase"
// other
import "date-fns"
import DateFnsUtils from "@date-io/date-fns"
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers"

let delayTimer

export default function Bio({ plaque, text }) {
  const [values, setValues] = useState({
    shortDescription: plaque.shortDescription || "",
    biography: plaque.biography || "",
    motherName: plaque.motherName || "",
    fatherName: plaque.fatherName || "",
    gender: plaque.gender || "",
    hebrewName: plaque.hebrewName || "",
    name: plaque.name || "",
    motherHebrew: plaque.motherHebrew || "",
  })

  const [selectedDate, setSelectedDate] = React.useState({
    death: plaque.death
      ? new Date(
          plaque.death.split("/")[2],
          plaque.death.split("/")[1] - 1,
          plaque.death.split("/")[0]
        )
      : new Date(),
    born: plaque.born
      ? new Date(
          plaque.born.split("/")[2],
          plaque.born.split("/")[1] - 1,
          plaque.born.split("/")[0]
        )
      : new Date(),
  })
  const handleDateChange = (date, prop) => {
    const stringDate = `${date.getDate()}/${
      date.getMonth() + 1
    }/${date.getFullYear()}`
    setSelectedDate({ ...selectedDate, [prop]: date })
    firestore
      .collection("plaques")
      .doc(plaque._id)
      .update({
        [prop]: stringDate,
      })
  }
  const handleInputChange = e => {
    e.persist()
    setValues({ ...values, [e.target.name]: e.target.value })
    clearTimeout(delayTimer)
    delayTimer = setTimeout(() => {
      firestore
        .collection("plaques")
        .doc(plaque._id)
        .update({
          [e.target.name]: e.target.value,
        })
    }, 1000)
  }
  return (
    <>
      <FormControl fullWidth margin="dense">
        <TextField
          name="name"
          label={text.plaqueEditorBioNameLabel}
          placeholder={text.plaqueEditorBioNamePlaceholder}
          value={values.name}
          onChange={handleInputChange}
        />
      </FormControl>
      <FormControl fullWidth margin="dense">
        <TextField
          dir="rtl"
          name="hebrewName"
          label={text.plaqueEditorBioHebrewNameLabel}
          placeholder={text.plaqueEditorBioHebrewNamePlaceholder}
          value={values.hebrewName}
          onChange={handleInputChange}
        />
      </FormControl>
      <FormControl fullWidth margin="dense">
        <TextField
          dir="rtl"
          name="motherHebrew"
          label={text.plaqueEditorBioMotherHebrewLabel}
          placeholder={text.plaqueEditorBioMotherHebrewPlaceholder}
          value={values.motherHebrew}
          onChange={handleInputChange}
        />
      </FormControl>
      <FormControl fullWidth margin="dense">
        <TextField
          name="motherName"
          label={text.plaqueEditorBioMotherNameLabel}
          placeholder={text.plaqueEditorBioMotherNamePlaceholder}
          value={values.motherName}
          onChange={handleInputChange}
        />
      </FormControl>
      <FormControl fullWidth margin="dense">
        <TextField
          name="fatherName"
          label={text.plaqueEditorBioFatherNameLabel}
          placeholder={text.plaqueEditorBioFatherNamePlaceholder}
          value={values.fatherName}
          onChange={handleInputChange}
        />
      </FormControl>
      <FormControl fullWidth>
        <InputLabel>{text.plaqueEditorBioGenderLabel}</InputLabel>
        <Select
          name="gender"
          value={values.gender}
          onChange={handleInputChange}
        >
          <MenuItem value="male">Male</MenuItem>
          <MenuItem value="female">Female</MenuItem>
        </Select>
      </FormControl>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6}>
            <FormControl fullWidth>
              <KeyboardDatePicker
                margin="normal"
                id="date-picker-dialog"
                label={text.orderFormBornLabel}
                format="dd/MM/yyyy"
                value={selectedDate.born}
                onChange={date => handleDateChange(date, "born")}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <FormControl fullWidth>
              <KeyboardDatePicker
                margin="normal"
                id="date-picker-dialog"
                label={text.orderFormDeathLabel}
                format="dd/MM/yyyy"
                value={selectedDate.death}
                onChange={date => handleDateChange(date, "death")}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </FormControl>
          </Grid>
        </Grid>
      </MuiPickersUtilsProvider>
      <FormControl fullWidth margin="dense">
        <TextField
          name="shortDescription"
          label={text.plaqueEditorBioShortDescLabel}
          placeholder={text.plaqueEditorBioShortDescPlaceholder}
          helperText="max. 140 char"
          multiline
          rows={3}
          value={values.shortDescription}
          onChange={handleInputChange}
          inputProps={{
            maxLength: 140,
          }}
        />
      </FormControl>
      <FormControl fullWidth margin="dense">
        <TextField
          name="biography"
          label={text.plaqueEditorBioDescLabel}
          placeholder={text.plaqueEditorBioDescPlaceholder}
          multiline
          rows={5}
          value={values.biography}
          onChange={handleInputChange}
        />
      </FormControl>
    </>
  )
}
