import React, { useState, useEffect } from "react"
import { makeStyles } from "@material-ui/core/styles"
import { Typography, ButtonGroup, Button, Paper } from "@material-ui/core"
import VolumeUpIcon from "@material-ui/icons/VolumeUp"
import AudioPlayer from "material-ui-audio-player"
import NavBar from "../../components/plaque/navBar"
import { useLocalization } from "gatsby-theme-i18n"
import { firestore, useFirestoreDoc } from "gatsby-theme-firebase"
import Backdrop from "../../components/backdrop"

const logo =
  "https://firebasestorage.googleapis.com/v0/b/ornechama-1a02f.appspot.com/o/public%2Fgold%20icons%2FKADDISH%20ICON.png?alt=media&token=5d19c162-90ad-4e1d-b91a-4f1f6b083a42"

const useStyles = makeStyles(theme => ({
  root: {
    fontFamily: "Lato",
    minHeight: "100vh",
    paddingBottom: theme.spacing(5),
  },
  surface: {
    padding: theme.spacing(2),
    margin: theme.spacing(2, 1),
  },
  controlSurface: {
    padding: theme.spacing(1),
    margin: theme.spacing(1),
  },
  centerContainer: {
    maxWidth: 500,
    marginLeft: "auto",
    marginRight: "auto",
  },
}))

// let months = ['Janvier', 'Février','Mars','Avril','Mai', 'Juin','Juillet','Aout','Septembre','Octobre','Novembre','Décembre']

const Kaddish = ({ plaqueId, userId, location }) => {
  const classes = useStyles()
  let {
    state: { traditions, plaque },
  } = location
  const [lang, setLang] = useState("he")
  const [display, setDisplay] = useState([])
  const kaddish = traditions.kaddish[plaque.kaddishType]

  const instructions = traditions.kaddishInstructions

  useEffect(() => {
    let val = []
    switch (lang) {
      case "ph":
        val = kaddish.ph.split(/\\n/)
        break
      case "fr":
        val = kaddish.fr.split(/\\n/)
        break
      case "en":
        val = kaddish.en.split(/\\n/)
        break
      case "sd":
        val = [kaddish.soundUrl]
        break
      default:
        val = kaddish.he.split(/\\n/)
        break
    }
    setDisplay(val)
  }, [lang, kaddish])
  const { locale } = useLocalization()
  const [text, isLoadingText] = useFirestoreDoc(
    firestore.collection(`text/localized/${locale}`).doc("kaddish")
  )
  if (isLoadingText || !text) return <Backdrop />
  return (
    <div className={classes.root}>
      <NavBar
        plaqueId={plaqueId}
        text={text.kaddish || "KADDISH"}
        logo={logo}
      />
      {/* Controls */}
      <div className={classes.centerContainer}>
        <Paper align="center" className={classes.controlSurface}>
          <ButtonGroup size="large" color="primary" variant="contained">
            <Button
              onClick={() => setLang("he")}
              color={lang === "he" ? "secondary" : "primary"}
              disabled={kaddish.he ? false : true}
            >
              He
            </Button>
            <Button
              onClick={() => setLang("ph")}
              color={lang === "ph" ? "secondary" : "primary"}
              disabled={kaddish.ph ? false : true}
            >
              Ph
            </Button>
            <Button
              onClick={() => setLang("fr")}
              color={lang === "fr" ? "secondary" : "primary"}
              disabled={kaddish.fr ? false : true}
            >
              Fr
            </Button>
            <Button
              onClick={() => setLang("en")}
              color={lang === "en" ? "secondary" : "primary"}
              disabled={kaddish.en ? false : true}
            >
              En
            </Button>
            <Button
              onClick={() => setLang("sd")}
              color={lang === "sd" ? "secondary" : "primary"}
              disabled={kaddish.soundUrl ? false : true}
              startIcon={<VolumeUpIcon />}
            ></Button>
          </ButtonGroup>
        </Paper>
        <Paper dir={lang === "he" ? "rtl" : "ltr"} className={classes.surface}>
          <Typography variant="h5" gutterBottom>
            {instructions[lang]}
          </Typography>
          {display.length > 0 &&
            display.map((line, i) => (
              <div key={i}>
                {lang !== "sd" ? (
                  <Typography>{line}</Typography>
                ) : (
                  <AudioPlayer
                    className={classes.audioPlayer}
                    src={line}
                    variation="primary"
                    volume={false}
                    time="single"
                  />
                )}
              </div>
            ))}
        </Paper>
      </div>
    </div>
  )
}

export default Kaddish
