import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import { Typography, Grid, Card, CardContent } from "@material-ui/core"
import NavBar from "../../components/plaque/navBar"
import Backdrop from "../../components/backdrop"
import {
  firestore,
  useFirestoreQuery,
  useFirestoreDoc,
} from "gatsby-theme-firebase"
import { useLocalization } from "gatsby-theme-i18n"

const logo =
  "https://firebasestorage.googleapis.com/v0/b/ornechama-1a02f.appspot.com/o/public%2Fgold%20icons%2FEVENT%20ICON.png?alt=media&token=8bed579f-53a1-48f9-9833-3fb669ecef9a"

const useStyles = makeStyles(theme => ({
  root: {
    fontFamily: "Lato",
    minHeight: "100vh",
    paddingBottom: theme.spacing(5),
  },
  surface: {
    padding: theme.spacing(2),
    margin: theme.spacing(2, 1),
  },
  grid: {
    padding: theme.spacing(2, 0.5),
  },
  item: {
    margin: theme.spacing(1),
  },
  centerContainer: {
    maxWidth: 500,
    marginLeft: "auto",
    marginRight: "auto",
  },
}))

const Bio = ({ plaqueId, userId }) => {
  const classes = useStyles()
  const [links, isLoading] = useFirestoreQuery(
    firestore.collection(`plaques/${plaqueId}/events`).orderBy("time", "asc")
  )
  const { locale } = useLocalization()
  const [text, isLoadingText] = useFirestoreDoc(
    firestore.collection(`text/localized/${locale}`).doc("links")
  )
  if (isLoadingText || !text || isLoading) return <Backdrop />
  return (
    <div className={classes.root}>
      <NavBar
        plaqueId={plaqueId}
        text={text.navBar || "ÉVÈNEMENTS ET LIENS"}
        logo={logo}
      />
      <div className={classes.centerContainer}>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="flex-start"
          className={classes.grid}
        >
          {links.length > 0 &&
            links.map((link, i) => (
              <Grid
                className={classes.item}
                item
                xs={12}
                sm={12}
                md={12}
                key={i}
              >
                <Card>
                  <CardContent>
                    <Typography align="center" variant="h6">
                      {link.title}
                    </Typography>
                    {link.content &&
                      link.content
                        .split("\n")
                        .map((line, i) =>
                          line === "" ? (
                            <br />
                          ) : (
                            <Typography key={i}>{line}</Typography>
                          )
                        )}
                  </CardContent>
                </Card>
              </Grid>
            ))}
          {links.length === 0 && (
            <Grid item xs={12} sm={12} md={12}>
              <Card>
                <CardContent>
                  <Typography variant="overline">
                    {text.noLink || "Aucun lien trouvé, désolé"}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          )}
        </Grid>
      </div>
    </div>
  )
}

export default Bio
