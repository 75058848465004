/* eslint-disable no-restricted-syntax */
/* eslint-disable no-await-in-loop */
import React, { useEffect, useState, useRef } from "react"
import { makeStyles } from "@material-ui/core/styles"
import Paper from "@material-ui/core/Paper"
import GridList from "@material-ui/core/GridList"
import GridListTile from "@material-ui/core/GridListTile"
import { useLocalization } from "gatsby-theme-i18n"
import Typography from "@material-ui/core/Typography"
// Data
import { firestore, useFirestoreDoc, firebase } from "gatsby-theme-firebase"
// Other
import Backdrop from "../../components/backdrop"
import NavBar from "../../components/plaque/navBar"


const logo =
  "https://firebasestorage.googleapis.com/v0/b/ornechama-1a02f.appspot.com/o/public%2Fgold%20icons%2FALBUM%20ICON.png?alt=media&token=ca064ae2-34b8-4b2c-bcb7-876551338edc"

const useStyles = makeStyles(theme => ({
  root: {
    fontFamily: "Lato",
    minHeight: "100vh",
    paddingBottom: theme.spacing(5),
  },
  surface: {
    padding: theme.spacing(2),
    margin: theme.spacing(2, 1),
  },
  img: {
    width: "100%",
    height: "100%",
    objectFit: "contain",
    overflow: "hidden",
  },
  centerContainer: {
    maxWidth: 500,
    marginLeft: "auto",
    marginRight: "auto",
  },
}))

const Album = ({ plaqueId, location }) => {
  const classes = useStyles()
  const { state: plaque } = location
  const storageRef = useRef(
    firebase.storage().ref(`plaqueData/${plaqueId}/${plaque.owner}/album`)
  )
  const [cloudPictures, setCloudPictures] = useState([])
  useEffect(() => {
    ;(async () => {
      const xPic = (await storageRef.current.listAll()).items
      for (const item of xPic) {
        item.download = await item.getDownloadURL()
      }
      // console.log(xPic);
      setCloudPictures(xPic)
    })()
  }, [])
  // Text stuff
  const { locale } = useLocalization()
  const [text, isLoadingText] = useFirestoreDoc(
    firestore.collection(`text/localized/${locale}`).doc("album")
  )
  if (isLoadingText || !text) return <Backdrop />
  return (
    <div className={classes.root}>
      <NavBar plaqueId={plaqueId} text={text.navBar || "ALBUM"} logo={logo} />
      <div className={classes.centerContainer}>
        <Paper className={classes.surface}>
          {cloudPictures.length > 0 && (
            <GridList>
              {cloudPictures.map(pic => {
                if (pic.download === plaque.mainImageUrl) return null
                if (pic.name === `${plaque._id}.png`) return null
                return (
                  <GridListTile key={pic.download}>
                    {/* eslint-disable-next-line */}
                    <img
                      src={pic.download}
                      alt={pic.name}
                      onClick={() => {
                        window.open(pic.download, "_blank")
                      }}
                    />
                  </GridListTile>
                )
              })}
            </GridList>
          )}
          {cloudPictures.length === 0 && (
            <Typography>{text.noImg || "Désolé, pas d'images"}</Typography>
          )}
        </Paper>
      </div>
    </div>
  )
}

export default Album
