import React, { useEffect, useState, useRef } from "react"
// Layout
import { makeStyles } from "@material-ui/core/styles"
import { Typography } from "@material-ui/core"
import Button from "@material-ui/core/Button"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import Checkbox from "@material-ui/core/Checkbox"
// Data
import { firestore, firebase } from "gatsby-theme-firebase"
// import firebase from "firebase/app"
// import "firebase/storage"
import ImageUploader from "react-images-upload"


const useStyles = makeStyles(() => ({
  button: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  img: {
    width: 50,
    height: 50,
    objectFit: "contain",
  },
}))

// if (!firebase.apps.length) {
//   firebase.initializeApp({
//     apiKey: "AIzaSyDqkiKpbEJT7Sx1H3LZdvJrvtWG0_OwOBI",
//     authDomain: "ornechama-1a02f.firebaseapp.com",
//     databaseURL: "https://ornechama-1a02f.firebaseio.com",
//     projectId: "ornechama-1a02f",
//     storageBucket: "ornechama-1a02f.appspot.com",
//     messagingSenderId: "200606886803",
//     appId: "1:200606886803:web:98bf802d4daeaa6ea6d666",
//   })
// } else {
//   firebase.app() // if already initialized, use that one
// }

export default function Media({ plaque, text }) {
  return (
    <>
      <MainManager plaque={plaque} text={text} />
      <AlbumManager plaque={plaque} text={text} />
    </>
  )
}

const AlbumManager = ({ plaque, text }) => {
  const classes = useStyles()
  const storageRef = useRef(
    firebase.storage().ref(`plaqueData/${plaque._id}/${plaque.owner}/album`)
  )
  // Upload things
  const [status, setStatus] = useState("")
  const [pictures, setPictures] = useState([])
  const onDrop = picture => {
    setPictures(picture)
  }
  const upload = async () => {
    setStatus("uploading")
    const newPics = [...pictures]
    for (const pic of pictures) {
      const currentIndex = pictures.indexOf(pic)
      newPics.splice(currentIndex, 1)
      try {
        const ref = storageRef.current.child(pic.name)
        await ref.put(pic)
      } catch (ex) {
        console.error(ex)
      }
    }
    setPictures(newPics)
    setStatus("done")
  }
  // List things
  const [cloudPictures, setCloudPictures] = useState([])
  const [checked, setChecked] = React.useState([])
  useEffect(() => {
    ;(async () => {
      const xPic = (await storageRef.current.listAll()).items
      for (const item of xPic) {
        item.download = await item.getDownloadURL()
      }
      // console.log(xPic);
      setCloudPictures(xPic)
    })()
  }, [status])
  const handleToggle = value => () => {
    const currentIndex = checked.indexOf(value)
    const newChecked = [...checked]
    if (currentIndex === -1) {
      newChecked.push(value)
    } else {
      newChecked.splice(currentIndex, 1)
    }
    setChecked(newChecked)
  }
  const deleteSelected = () => {
    const newCloud = [...cloudPictures]
    const newChecked = [...checked]
    for (const pic of checked) {
      const currentIndexCloud = cloudPictures.indexOf(pic)
      newCloud.splice(currentIndexCloud, 1)
      const currentIndexChecked = checked.indexOf(pic)
      newChecked.splice(currentIndexChecked, 1)
      try {
        pic.delete()
      } catch (ex) {
        console.error(ex)
      }
    }
    setChecked(newChecked)
    setCloudPictures(newCloud)
  }
  return (
    <>
      <Typography align="center" variant="h6">
        {text.plaqueEditorMediaTitle}
      </Typography>
      <Typography align="center" variant="body2">
        {text.plaqueEditorMediaSubtitle}
      </Typography>
      <ImageUploader
        onChange={onDrop}
        withPreview
        imgExtension={[".jpg", ".jpeg", ".png"]}
        buttonText={text.plaqueEditorMediaSelectFilesBtnText}
      />
      <div className={classes.button}>
        <Button
          disabled={pictures.length === 0}
          variant="contained"
          size="small"
          color="primary"
          onClick={upload}
        >
          {text.plaqueEditorMediaUploadBtn}
        </Button>
      </div>
      {cloudPictures.length > 0 && (
        <>
          <Typography align="left" variant="body1">
            {text.plaqueEditorMediaImgListTitle}
          </Typography>
          <List className={classes.root}>
            {cloudPictures.map(value => {
              const labelId = `${value}`
              if (value.download === plaque.mainImageUrl) return null
              if (value.name === `${plaque._id}.png`) return null
              return (
                <ListItem
                  key={value}
                  role={undefined}
                  dense
                  button
                  onClick={handleToggle(value)}
                >
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={checked.indexOf(value) !== -1}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ "aria-labelledby": labelId }}
                    />
                    <img className={classes.img} alt="" src={value.download} />
                  </ListItemIcon>
                </ListItem>
              )
            })}
          </List>
          <div className={classes.button}>
            <Button
              disabled={checked.length === 0}
              variant="contained"
              size="small"
              color="primary"
              onClick={deleteSelected}
            >
              {text.plaqueEditorMediaImgListDelBtn}
            </Button>
          </div>
        </>
      )}
    </>
  )
}

const MainManager = ({ plaque, text }) => {
  const classes = useStyles()
  const storageRef = useRef(
    firebase.storage().ref(`plaqueData/${plaque._id}/${plaque.owner}/main`)
  )
  // Upload things
  const [status, setStatus] = useState("")
  const [pictures, setPictures] = useState([])
  const onDrop = picture => {
    setPictures(picture)
  }
  const upload = async () => {
    setStatus("uploading")
    const newPics = [...pictures]
    if (cloudPictures.length > 0) await cloudPictures[0].delete()
    for (const pic of pictures) {
      const currentIndex = pictures.indexOf(pic)
      newPics.splice(currentIndex, 1)
      try {
        const ref = storageRef.current.child(pic.name)
        await ref.put(pic)
        const mainUrl = await ref.getDownloadURL()
        firestore.collection("plaques").doc(plaque._id).update({
          "imgs.main": mainUrl,
        })
      } catch (ex) {
        console.error(ex)
      }
    }
    setPictures(newPics)
    setStatus("done")
  }
  // List things
  const [cloudPictures, setCloudPictures] = useState([])
  const [checked, setChecked] = React.useState([])
  useEffect(() => {
    ;(async () => {
      const xPic = (await storageRef.current.listAll()).items
      for (const item of xPic) {
        item.download = await item.getDownloadURL()
      }
      // console.log(xPic);
      setCloudPictures(xPic)
    })()
  }, [status])
  const handleToggle = value => () => {
    const currentIndex = checked.indexOf(value)
    const newChecked = [...checked]
    if (currentIndex === -1) {
      newChecked.push(value)
    } else {
      newChecked.splice(currentIndex, 1)
    }
    setChecked(newChecked)
  }
  const deleteSelected = () => {
    const newCloud = [...cloudPictures]
    const newChecked = [...checked]
    for (const pic of checked) {
      const currentIndexCloud = cloudPictures.indexOf(pic)
      newCloud.splice(currentIndexCloud, 1)
      const currentIndexChecked = checked.indexOf(pic)
      newChecked.splice(currentIndexChecked, 1)
      try {
        pic.delete()
        firestore.collection("plaques").doc(plaque._id).update({
          "imgs.main": "",
        })
      } catch (ex) {
        console.error(ex)
      }
    }
    setChecked(newChecked)
    setCloudPictures(newCloud)
  }
  return (
    <>
      <Typography align="center" variant="h6">
        {text.plaqueEditorMediaMainTitle}
      </Typography>
      <Typography align="center" variant="body2">
        {text.plaqueEditorMediaMainSubtitle}
      </Typography>
      <ImageUploader
        onChange={onDrop}
        withPreview
        imgExtension={[".jpg", ".jpeg", ".png"]}
        buttonText={text.plaqueEditorMediaMainSelectFilesBtnText}
        singleImage
      />
      <div className={classes.button}>
        <Button
          disabled={pictures.length === 0 || pictures.length > 3}
          variant="contained"
          size="small"
          color="primary"
          onClick={upload}
        >
          {text.plaqueEditorMediaUploadBtn}
        </Button>
      </div>
      {cloudPictures.length > 0 && (
        <>
          <Typography align="left" variant="body1">
            {text.plaqueEditorMediaMainImgListTitle}
          </Typography>
          <List className={classes.root}>
            {cloudPictures.map(value => {
              const labelId = `${value}`
              return (
                <ListItem
                  key={value}
                  role={undefined}
                  dense
                  button
                  onClick={handleToggle(value)}
                >
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={checked.indexOf(value) !== -1}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ "aria-labelledby": labelId }}
                    />
                    <img className={classes.img} alt="" src={value.download} />
                  </ListItemIcon>
                </ListItem>
              )
            })}
          </List>
          <div className={classes.button}>
            <Button
              disabled={checked.length === 0}
              variant="contained"
              size="small"
              color="primary"
              onClick={deleteSelected}
            >
              {text.plaqueEditorMediaImgListDelBtn}
            </Button>
          </div>
        </>
      )}
    </>
  )
}
