import React from "react"
//Layout
import { makeStyles } from "@material-ui/core/styles"
import NavBar from "../../components/app/navBar"
import { Typography, Paper } from "@material-ui/core/"
//Data feed and Misc.
import { useLocalization } from "gatsby-theme-i18n"
import {
  firestore,
  useFirestoreDoc,
  useFirestoreQuery,
} from "gatsby-theme-firebase"
import Backdrop from "../../components/backdrop"
//Table
import { forwardRef } from "react"
import MaterialTable from "material-table"
import AddBox from "@material-ui/icons/AddBox"
import ArrowDownward from "@material-ui/icons/ArrowDownward"
import Check from "@material-ui/icons/Check"
import ChevronLeft from "@material-ui/icons/ChevronLeft"
import ChevronRight from "@material-ui/icons/ChevronRight"
import Clear from "@material-ui/icons/Clear"
import DeleteOutline from "@material-ui/icons/DeleteOutline"
import Edit from "@material-ui/icons/Edit"
import FilterList from "@material-ui/icons/FilterList"
import FirstPage from "@material-ui/icons/FirstPage"
import LastPage from "@material-ui/icons/LastPage"
import Remove from "@material-ui/icons/Remove"
import SaveAlt from "@material-ui/icons/SaveAlt"
import Search from "@material-ui/icons/Search"
import ViewColumn from "@material-ui/icons/ViewColumn"

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
}

const useStyles = makeStyles(theme => ({
  root: {
    flex: 1,
    minHeight: "100vh",
    paddingBottom: theme.spacing(5),
  },
  table: {},
}))

const MsgTable = ({ user, location }) => {
  const classes = useStyles()
  const {
    state: { plaqueId },
  } = location
  //Text
  const { locale } = useLocalization()
  const [text, isLoading] = useFirestoreDoc(
    firestore.collection("text").doc(locale)
  )
  // console.log(text, isLoading, error);
  //   console.log(user,plaqueId);
  const [msg, isLoadingMsg] = useFirestoreQuery(
    firestore.collection(`plaques/${plaqueId}/messages`)
  )
  console.log(msg, isLoadingMsg)
  if (isLoading || !text || isLoadingMsg) return <Backdrop />
  return (
    <div className={classes.root}>
      <NavBar text={text} location={text.msgNavBarText} />
      <MaterialTable
        title="Messages"
        style={{
          width: "95%",
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: "5%",
        }}
        icons={tableIcons}
        data={msg}
        columns={[
          { title: "Name", field: "name" },
          { title: "Date", field: "timestamp" },
          { title: "Location", field: "location" },
        ]}
        actions={[
          {
            icon: tableIcons.Delete,
            tooltip: "Delete message",
            onClick: (event, rowData) =>
              firestore
                .collection(`/plaques/${plaqueId}/messages`)
                .doc(rowData._id)
                .delete(),
          },
        ]}
        detailPanel={rowData => (
          <Paper style={{ padding: 20 }}>
            <Typography>{rowData.message}</Typography>
          </Paper>
        )}
        options={{
          actionsColumnIndex: -1,
        }}
      />
    </div>
  )
}

export default MsgTable
