/* eslint-disable react/jsx-props-no-spreading */
import React from "react"
import { navigate } from "gatsby"
import { useAuth } from "gatsby-theme-firebase"
import Backdrop from "../components/backdrop"


const PrivateRoute = ({ component: Component, location, ...rest }) => {
  const { isLoading, isLoggedIn, profile } = useAuth()

  if (!isLoggedIn && !isLoading && location.pathname !== `/login`) {
    navigate("/login")
    return null
  }
  if (!isLoading && isLoggedIn && location.pathname === `/app/admin`) {
    profile.getIdTokenResult().then(d => {
      if (!d.claims.admin) navigate("/app")
    })
  }
  if (isLoading || !profile) return <Backdrop />
  return <Component user={profile} location={location} {...rest} />
}

export default PrivateRoute
