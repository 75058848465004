import React, { useState } from "react"
import { navigate } from "gatsby"
// Layout and input
import { makeStyles } from "@material-ui/core/styles"
import Stepper from "@material-ui/core/Stepper"
import Step from "@material-ui/core/Step"
import StepLabel from "@material-ui/core/StepLabel"
import StepContent from "@material-ui/core/StepContent"
import Button from "@material-ui/core/Button"
import Paper from "@material-ui/core/Paper"
import Typography from "@material-ui/core/Typography"
// other
import { useLocalization } from "gatsby-theme-i18n"
import NavigateNextIcon from "@material-ui/icons/NavigateNext"
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore"
import SendIcon from "@material-ui/icons/Send"
import Bio from "../plaqueForms/bio"
import Location from "../plaqueForms/location"
import Traditions from "../plaqueForms/traditions"
import Media from "../plaqueForms/media"
import Events from "../plaqueForms/events"


const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
}))

const PlaqueEditor = ({ plaque, text }) => {
  function getSteps() {
    return [
      text.plaqueEditorBioTitle,
      text.plaqueEditorLocationTitle,
      text.plaqueEditorTraditionsTitle,
      text.plaqueEditorMediaTitle,
      text.plaqueEditorEventsTitle,
    ]
  }
  const classes = useStyles()
  const { locale, defaultLang } = useLocalization()
  // Stepper stuff
  const [activeStep, setActiveStep] = useState(0)
  const steps = getSteps()

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1)
  }
  function getStepContent(step) {
    switch (step) {
      case 0:
        return <Bio text={text} plaque={plaque} />
      case 1:
        return <Location text={text} plaque={plaque} />
      case 2:
        return <Traditions text={text} plaque={plaque} />
      case 3:
        return <Media text={text} plaque={plaque} />
      case 4:
        return <Events text={text} plaque={plaque} />
      default:
        return "Unknown step"
    }
  }
  return (
    <div className={classes.root}>
      <Typography align="center" variant="subtitle2" color="secondary">
        {text.plaqueEditorInfo}
      </Typography>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((label, index) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
            <StepContent>
              <Typography component="div">{getStepContent(index)}</Typography>
              <div className={classes.actionsContainer}>
                <div>
                  <Button
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    className={classes.button}
                  >
                    <NavigateBeforeIcon />
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleNext}
                    className={classes.button}
                  >
                    {activeStep === steps.length - 1 ? (
                      <SendIcon />
                    ) : (
                      <NavigateNextIcon />
                    )}
                  </Button>
                </div>
              </div>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {activeStep === steps.length && (
        <Paper square elevation={0} className={classes.resetContainer}>
          <Typography>All steps completed - you&apos;re finished</Typography>
          <Button
            disabled={activeStep === 0}
            onClick={handleBack}
            className={classes.button}
          >
            <NavigateBeforeIcon />
          </Button>
          <Button
            disabled={activeStep === 0}
            onClick={() =>
              navigate(`${locale === defaultLang ? "" : `/${  locale}`}/app`)
            }
            className={classes.button}
          >
            {text.plaqueEditorToDashBtn}
          </Button>
          <Button
            disabled={activeStep === 0}
            onClick={() =>
              navigate(
                `${locale === defaultLang ? "" : `/${  locale}`}/app/plaque/${
                  plaque._id
                }`
              )
            }
            className={classes.button}
          >
            {text.plaqueEditorPreview}
          </Button>
        </Paper>
      )}
    </div>
  )
}

export default PlaqueEditor
