import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import NavBar from "../../components/plaque/navBar"
import Psalms from "../../components/plaque/psalms"
//text
import { firestore, useFirestoreDoc } from "gatsby-theme-firebase"
import { useLocalization } from "gatsby-theme-i18n"
import Backdrop from "../../components/backdrop"

const logo =
  "https://firebasestorage.googleapis.com/v0/b/ornechama-1a02f.appspot.com/o/public%2Fgold%20icons%2FPSAUME%20ALPHA%20BETA%20ICON.png?alt=media&token=ebe4a28b-3612-494a-b225-03f6d2653623"

const useStyles = makeStyles(theme => ({
  root: {
    fontFamily: "Lato",
    minHeight: "100vh",
    paddingBottom: theme.spacing(5),
  },
  surface: {
    padding: theme.spacing(2),
    margin: theme.spacing(2, 1),
  },
  controlSurface: {
    padding: theme.spacing(1),
  },
  centerContainer: {
    maxWidth: 500,
    marginLeft: "auto",
    marginRight: "auto",
  },
}))

// let months = ['Janvier', 'Février','Mars','Avril','Mai', 'Juin','Juillet','Aout','Septembre','Octobre','Novembre','Décembre']

const AlphaBeta = ({ plaqueId, location }) => {
  const classes = useStyles()
  const {
    state: { traditions, plaque },
  } = location
  let afil = `${
    plaque.motherHebrew
      ? `${plaque.hebrewName} ${plaque.gender === "male" ? "בן" : "בת"} ${
          plaque.motherHebrew
        }`
      : plaque.hebrewName
  }`
  let set = afil
    .split("")
    .map(char => {
      let index = traditions.psalms.findIndex(
        element => element.symbol === char
      )
      // if(index != -1)console.log('char', char,'symbol', traditions.psalms[index].symbol);
      return index
    })
    .filter(el => el !== -1)
  // add a flag to signify ending symbol
  console.log(set)
  //Text stuff
  const { locale } = useLocalization()
  const [text, isLoadingText] = useFirestoreDoc(
    firestore.collection(`text/localized/${locale}`).doc("alphaBeta")
  )
  if (isLoadingText || !text) return <Backdrop />
  return (
    <div className={classes.root}>
      <NavBar
        plaqueId={plaqueId}
        text={text.navBar || "PSAUMES ALPHA BETA"}
        logo={logo}
      />
      <div className={classes.centerContainer}>
        <Psalms source={traditions.psalms} set={set} />
      </div>
    </div>
  )
}

export default AlphaBeta
