import React, { useState } from "react"
import PaymentIcon from "@material-ui/icons/Payment"
import Button from "@material-ui/core/Button"
import Grid from "@material-ui/core/Grid"
// Stripe
import { loadStripe } from "@stripe/stripe-js"
import Backdrop from "./backdrop"

const stripePromise = loadStripe("pk_live_tCcUeSYJRvSmjtVuPoNzmGSL00r1qEuKVz")

export default function PayButton({ cartItems, user, text }) {
  // console.log('Button',cartItems);
  const [clicked, setClicked] = useState(false)
  const handleClick = async () => {
    setClicked(true)
    const body = {
      cart: cartItems,
      user,
    }
    try {
      // Get Stripe.js instance
      const stripe = await stripePromise
      // Call your backend to create the Checkout Session
      const response = await fetch(
        `${window.location.origin}/fns/createCheckoutSession`,
        {
          method: "POST",
          body: JSON.stringify(body),
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      const session = await response.json()
      // When the customer clicks on the button, redirect them to Checkout.
      await stripe.redirectToCheckout({
        sessionId: session.id,
      })
    } catch (ex) {
      // console.log(
      //   "dude remeber that to test this you have to build and serve..."
      // )
      // eslint-disable-next-line no-console
      console.error(ex)
      // If `redirectToCheckout` fails due to a browser or network
      // error, display the localized error message to your customer
      // using `result.error.message`.
    }
  }

  return (
    <>
      <Grid container justify="flex-end">
        <Grid item>
          <Button
            startIcon={<PaymentIcon />}
            variant="contained"
            color="secondary"
            onClick={handleClick}
            role="link"
            disabled={!cartItems.length}
          >
            {text}
          </Button>
        </Grid>
      </Grid>
      {clicked && <Backdrop />}
    </>
  )
}
