import React from "react"
import Container from "@material-ui/core/Container"
import Typography from "@material-ui/core/Typography"


const browser = typeof window !== "undefined" && window

const NotFound = () =>
  browser && (
    <>
      <Container style={{ minHeight: "100vh" }}>
        <Typography variant="h1" align="center" gutterBottom>
          404
        </Typography>
        <Typography variant="h4" align="center">
          Page not found. This means that there&apos;s probably nothing at this
          address!
        </Typography>
      </Container>
    </>
  )

export default NotFound
