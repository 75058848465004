import React from "react"
// Layout
import { Grid } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
// Data
import { firestore, useFirestoreQuery } from "gatsby-theme-firebase"
// Other
import Backdrop from "../backdrop"
import PlaqueCard from "./plaqueCard"

const useStyles = makeStyles(theme => ({
  root: {
    flex: 1,
  },
  grid: {
    marginTop: theme.spacing(3),
  },
  plaque: {
    padding: theme.spacing(2),
  },
}))

const Album = ({ user, text }) => {
  const classes = useStyles()

  const [plaques, isLoading] = useFirestoreQuery(
    firestore.collection(`plaques`).where("owner", "==", user.uid)
  )

  if (isLoading) return <Backdrop />
  return (
    <div className={classes.root}>
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        className={classes.grid}
      >
        {plaques.map(plaqueInfo => (
          <Grid
            className={classes.plaque}
            item
            xs={12}
            sm={6}
            md={4}
            lg={3}
            key={plaqueInfo._id}
          >
            <PlaqueCard text={text} user={user.uid} data={plaqueInfo} />
          </Grid>
        ))}
      </Grid>
    </div>
  )
}

export default Album
