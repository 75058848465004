import React from "react"
//Layout
import { makeStyles } from "@material-ui/core/styles"
import Container from "@material-ui/core/Container"
import Paper from "@material-ui/core/Paper"
//Data feed and Misc.
import { useLocalization } from "gatsby-theme-i18n"
import { firestore, useFirestoreDoc } from "gatsby-theme-firebase"
import Backdrop from "../../components/backdrop"
//other
import NavBar from "../../components/app/navBar"
import OrderForm from "../../components/app/orderForm"
import PlaqueEditor from "../../components/app/plaqueEditor"

const useStyles = makeStyles(theme => ({
  root: {
    flex: 1,
    minHeight: "100vh",
    paddingBottom: theme.spacing(5),
  },
  container: {},
  surface: {
    marginTop: theme.spacing(6),
    padding: theme.spacing(5, 2.5),
  },
}))

const EditPlaque = ({ location }) => {
  const classes = useStyles()
  const { locale } = useLocalization()
  const [text, isLoadingText] = useFirestoreDoc(
    firestore.collection("text").doc(locale)
  )
  const [plaque, isLoadingPlaque] = useFirestoreDoc(
    firestore.collection(`plaques`).doc(location.state.plaqueId)
  )
  if (isLoadingText || isLoadingPlaque || !plaque || !text) return <Backdrop />
  return (
    <div className={classes.root}>
      <NavBar
        text={text}
        location={
          plaque.ordered
            ? text.editPlaqueNavBarTextEdit
            : text.editPlaqueNavBarTextOrder
        }
      />
      <Container className={classes.container}>
        <Paper className={classes.surface}>
          {plaque.ordered ? (
            <PlaqueEditor text={text} plaque={plaque} />
          ) : (
            <OrderForm text={text} plaque={plaque._id} />
          )}
        </Paper>
      </Container>
    </div>
  )
}

export default EditPlaque
