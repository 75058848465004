import React, { useState } from "react"
// Layout and input
import { makeStyles } from "@material-ui/core/styles"
import { Typography, TextField, Grid, Button, Link } from "@material-ui/core"
import FormControl from "@material-ui/core/FormControl"
// data
import { firestore } from "gatsby-theme-firebase"
// other
import "date-fns"
import DateFnsUtils from "@date-io/date-fns"
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers"


const useStyles = makeStyles(theme => ({
  root: {},
  buttonItem: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
  },
  select: {
    minWidth: theme.spacing(20),
  },
  rtl: {
    direction: "rtl",
  },
}))

const OrderForm = ({ plaque, text }) => {
  const classes = useStyles()
  const [selectedDate, setSelectedDate] = React.useState({
    death: new Date(),
    born: new Date(),
  })
  const [values, setValues] = useState({
    name: "",
    hebrewName: "",
    // knownBirth: false
  })
  // const onDrop = pic => {
  //   setPicture(pic);
  // };

  // const upload = async () =>{
  //   let downloadUrl
  //   try{
  //     console.log('name:',picture[0]);
  //     let ref = storageRef.child(picture[0].name);
  //     await ref.put(picture[0]);
  //     downloadUrl= await ref.getDownloadURL()
  //     console.log(downloadUrl);
  //   }catch(ex){
  //       console.log(ex);
  //   }
  //   return downloadUrl
  // }

  const handleDateChange = (date, prop) => {
    setSelectedDate({ ...selectedDate, [prop]: date })
  }

  const handleInputChange = e => {
    setValues({ ...values, [e.target.name]: e.target.value })
  }

  // const toggeClick = () => {
  //   setValues({...values, knownBirth: !values.knownBirth})
  // }
  const handleForm = async e => {
    e.preventDefault()
    const born = `${selectedDate.born.getDate()}/${
      selectedDate.born.getMonth() + 1
    }/${selectedDate.born.getFullYear()}`
    const death = `${selectedDate.death.getDate()}/${
      selectedDate.death.getMonth() + 1
    }/${selectedDate.death.getFullYear()}`
    await firestore
      .collection(`plaques`)
      .doc(plaque)
      .update({
        ordered: true,
        ...values,
        born,
        death,
      })
    // Launch webhook to signal order placed...
  }
  return (
    <div className={classes.root}>
      <form onSubmit={handleForm}>
        <Typography align="center" variant="h6" component="h2">
          {text.orderFormTitle}
        </Typography>
        <Typography align="center" variant="body2" component="p" gutterBottom>
          {text.orderFormSubtitle}
        </Typography>
        <Grid container alignItems="center" justify="center" spacing={2}>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <TextField
                value={values.name}
                onChange={handleInputChange}
                name="name"
                required
                label={text.orderFormNameLabel}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <TextField
                className={classes.rtl}
                value={values.hebrewName}
                onChange={handleInputChange}
                name="hebrewName"
                label={text.orderFormHebrewNameLabel}
                helperText={
                  <Link
                    color="secondary"
                    target="_blank"
                    href="https://www.branah.com/hebrew"
                  >
                    hebrew keyboard
                  </Link>
                }
              />
            </FormControl>
          </Grid>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid item xs={12} sm={12} md={6}>
              <FormControl fullWidth>
                <KeyboardDatePicker
                  margin="normal"
                  id="date-picker-dialog"
                  label={text.orderFormBornLabel}
                  format="dd/MM/yyyy"
                  value={selectedDate.born}
                  onChange={date => handleDateChange(date, "born")}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <FormControl fullWidth>
                <KeyboardDatePicker
                  required
                  margin="normal"
                  id="date-picker-dialog"
                  label={text.orderFormDeathLabel}
                  format="dd/MM/yyyy"
                  value={selectedDate.death}
                  onChange={date => handleDateChange(date, "death")}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </FormControl>
            </Grid>
          </MuiPickersUtilsProvider>
          <Grid item xs={12} className={classes.buttonItem}>
            <Button variant="contained" color="secondary" type="submit">
              {text.orderFormBtn}
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  )
}

export default OrderForm
