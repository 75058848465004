import React from "react"
//Layout and Styling
import { makeStyles } from "@material-ui/core/styles"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import IconButton from "@material-ui/core/IconButton"
import Typography from "@material-ui/core/Typography"
import GoldBar from "../goldBar"
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft"
import { navigate } from "gatsby"

const useStyles = makeStyles(theme => ({
  root: {
    fontFamily: "Lato",
  },
  surface: {
    padding: theme.spacing(2),
    margin: theme.spacing(2, 1),
  },
  title: {
    flexGrow: 1,
    fontFamily: "Lato",
  },
  fullList: {
    width: "auto",
  },
  goldBar: {
    position: "relative",
    bottom: theme.spacing(1),
  },
  logo: {
    height: 50,
    margin: theme.spacing(1, 2, 1, 0),
  },
}))

export default function NavBar({ text, logo, plaqueId, userId }) {
  const classes = useStyles()
  // const [state, setState] = React.useState({
  //     drawerOpen: false,
  // })
  // const toggleDrawer = (e) => {
  // if (e && e.type === 'keydown' && (e.key === 'Tab' || e.key === 'Shift')) {
  //     return;
  // }
  // setState({ ...state, drawerOpen: !state.drawerOpen });
  // };
  const goBack = () => navigate(`/app/plaque/${plaqueId}`)
  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar spacing={2}>
          <img alt="" src={logo} className={classes.logo} loading="lazy" />
          <Typography
            className={classes.title}
            variant="overline"
            component="h5"
          >
            {text}
          </Typography>
          <IconButton
            edge="end"
            className={classes.menuButton}
            color="secondary"
            aria-label="menu"
            onClick={goBack}
          >
            <ChevronLeftIcon />
          </IconButton>
        </Toolbar>
        <GoldBar className={classes.goldBar} />
      </AppBar>
    </div>
  )
}
