import React, { useState } from "react"
// Layout
import { makeStyles } from "@material-ui/core/styles"
import {
  Grid,
  Avatar,
  Typography,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Button,
  Modal,
  TextField,
} from "@material-ui/core"
// Data feed and Misc.
import { useLocalization } from "gatsby-theme-i18n"
import { firestore, useFirestoreDoc } from "gatsby-theme-firebase"
import NavBar from "../../components/app/navBar"
import Backdrop from "../../components/backdrop"


const useStyles = makeStyles(theme => ({
  root: {
    flex: 1,
    minHeight: "100vh",
    paddingBottom: theme.spacing(5),
  },
  avatar: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    marginLeft: theme.spacing(3),
  },
  grid: {
    marginTop: theme.spacing(5),
  },
  mod: {
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
  },
  modalCard: {
    width: "80vw",
  },
}))

const UserSettings = ({ user }) => {
  const [open, setOpen] = useState(false)
  const [newEmail, setNewEmail] = useState("")
  const classes = useStyles()
  // Text
  const { locale } = useLocalization()
  const [text, isLoading] = useFirestoreDoc(
    firestore.collection("text").doc(locale)
  )
  // console.log(text, isLoading, error);
  // console.log(user)
  const sendVerification = () => {
    user.sendEmailVerification()
  }
  const openModal = () => {
    setOpen(true)
  }
  const closeModal = () => {
    setOpen(false)
  }
  const sendNewEmail = () => {
    user.updateEmail(newEmail)
  }
  if (isLoading || !text) return <Backdrop />
  return (
    <div className={classes.root}>
      <NavBar text={text} location={text.settingsNavBarText || "Settings"} />
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        className={classes.grid}
      >
        <Grid item>
          <Card>
            <CardHeader
              avatar={
                <Avatar
                  aria-label="user"
                  src={user.photoURL}
                  className={classes.avatar}
                />
              }
              title={user.displayName}
              subheader={user.email}
            />
            <CardContent>
              <Typography>
                {user.emailVerified
                  ? text.settingsEmailVerifiedMsg || "Email verified"
                  : text.settingsEmailNotVerifiedMsg ||
                    "Please verify email or change it"}
              </Typography>
            </CardContent>
            <CardActions>
              <Button onClick={openModal}>
                {text.settingsChangeEmailBtn || "Change email"}
              </Button>
              <Button onClick={sendVerification} disabled={user.emailVerified}>
                {text.settingsVerifyEmailBtn || "Verify Email"}
              </Button>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
      <Modal
        className={classes.mod}
        open={open}
        onClose={closeModal}
        aria-labelledby="email-change-modal"
        aria-describedby="modal for the user to input the new email"
      >
        <Card className={classes.modalCard}>
          <CardContent>
            <Typography variant="overline">
              {text.settingsChangeEmailModalInstructions ||
                "For security reasons, you must have logged in recently to do this. If you haven't, logout and then login and come back."}
            </Typography>
            <TextField
              fullWidth
              label={text.settingsChangeEmailModalInputLabel || "New email"}
              helperText="For security, when a user changes their email address, an email is sent to their original address so they can review the change."
              value={newEmail}
              onChange={e => setNewEmail(e.target.value)}
              type="email"
            />
          </CardContent>
          <CardActions>
            <Button onClick={sendNewEmail}>
              {text.settingsChangeEmailSendBtn || "Send"}
            </Button>
            <Button onClick={closeModal}>
              {text.settingsChangeEmailCancelBtn || "Cancel"}
            </Button>
          </CardActions>
        </Card>
      </Modal>
    </div>
  )
}

export default UserSettings
