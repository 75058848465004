import React, { useState, useEffect } from "react"
import { makeStyles } from "@material-ui/core/styles"
import { Typography, ButtonGroup, Button, Paper, Grid } from "@material-ui/core"
import VolumeUpIcon from "@material-ui/icons/VolumeUp"
import AudioPlayer from "material-ui-audio-player"
import _ from "lodash"

const useStyles = makeStyles(theme => ({
  root: {
    fontFamily: "Lato",
  },
  surface: {
    padding: theme.spacing(2),
    margin: theme.spacing(2, 1),
  },
  controlSurface: {
    margin: theme.spacing(1),
    padding: theme.spacing(1),
  },
  symbolContainer: {
    background:
      "linear-gradient(50deg, rgba(253,233,211,1) 0%, rgba(197,161,120,1) 50%, rgba(253,233,211,1) 100%)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 10,
    width: theme.spacing(6),
    height: theme.spacing(6),
    padding: theme.spacing(0.2),
    margin: theme.spacing(0, "auto", 0, "auto"),
  },
  symbolSurface: {
    backgroundColor: theme.palette.primary.main,
    width: "95%",
    height: "95%",
    borderRadius: 10,
  },
  symbol: {
    fontSize: 30,
  },
  para: {
    marginBottom: theme.spacing(4),
  },
  paragraphHeading: {
    marginBottom: theme.spacing(2),
  },
}))

const Psalms = ({ source, set }) => {
  const classes = useStyles()
  set = set ? set : _.range(22)
  const psalms = set.map(index => {
    return source[index]
  })
  const [lang, setLang] = useState("he")
  const [display, setDisplay] = useState([])
  useEffect(() => {
    let val = []
    if (psalms.length > 0) {
      switch (lang) {
        case "ph":
          val = psalms.map(psalm => {
            let text = psalm.ph.split("\\n")
            return { text: text, symbol: psalm.symbol, url: psalm.soundUrl }
          })
          break
        case "fr":
          val = psalms.map(psalm => {
            let text = psalm.fr.replace(/!/g, "!0").split(/0/g)
            return { text: text, symbol: psalm.symbol, url: psalm.soundUrl }
          })
          break
        case "en":
          val = psalms.map(psalm => {
            let text = psalm.en.replace(/!/g, "!0").split(/0/g)
            return { text: text, symbol: psalm.symbol, url: psalm.soundUrl }
          })
          break
        case "sd":
          val = psalms.map(psalm => {
            return { url: psalm.soundUrl, symbol: psalm.symbol }
          })
          console.log(val)
          break
        default:
          val = psalms.map(psalm => {
            let text = psalm.he.replace(/:/g, ":0").split(/0/g)
            return { text: text, symbol: psalm.symbol, url: psalm.soundUrl }
          })
          break
      }
    }
    setDisplay(val)
    // console.log(lang, val);
    // eslint-disable-next-line
  }, [lang])
  return (
    <div className={classes.root}>
      {/* Controls */}
      <Paper align="center" className={classes.controlSurface}>
        <ButtonGroup size="large" color="primary" variant="contained">
          <Button
            onClick={() => setLang("he")}
            color={lang === "he" ? "secondary" : "primary"}
            disabled={psalms.length === 0 || psalms[0].he ? false : true}
          >
            He
          </Button>
          <Button
            onClick={() => setLang("ph")}
            color={lang === "ph" ? "secondary" : "primary"}
            disabled={psalms.length === 0 || psalms[0].ph ? false : true}
          >
            Ph
          </Button>
          <Button
            onClick={() => setLang("fr")}
            color={lang === "fr" ? "secondary" : "primary"}
            disabled={psalms.length === 0 || psalms[0].fr ? false : true}
          >
            Fr
          </Button>
          <Button
            onClick={() => setLang("en")}
            color={lang === "en" ? "secondary" : "primary"}
            disabled={psalms.length === 0 || psalms[0].en ? false : true}
          >
            En
          </Button>
          <Button
            onClick={() => setLang("sd")}
            color={lang === "sd" ? "secondary" : "primary"}
            disabled={psalms.length === 0 || psalms[0].soundUrl ? false : true}
            startIcon={<VolumeUpIcon />}
          ></Button>
        </ButtonGroup>
      </Paper>
      {/* Psalms/Content */}
      <Paper dir={lang === "he" ? "rtl" : "ltr"} className={classes.surface}>
        {display.length > 0 &&
          display.map((psalm, i) => (
            <div className={classes.para} key={i}>
              <Grid
                container
                justify="center"
                alignItems="center"
                className={classes.paragraphHeading}
              >
                <Grid xs item>
                  <div align="center" className={classes.symbolContainer}>
                    <div className={classes.symbolSurface}>
                      <Typography
                        variant="h3"
                        className={classes.symbol}
                        color="secondary"
                      >
                        {psalm.symbol}
                      </Typography>
                    </div>
                  </div>
                </Grid>
                {lang !== "sd" && (
                  <Grid item xs={9} dir="ltr">
                    <AudioPlayer
                      src={psalm.url}
                      variation="primary"
                      volume={false}
                      time="single"
                      key={i}
                    />
                  </Grid>
                )}
              </Grid>
              {psalm.text ? (
                psalm.text.map((line, i) => (
                  <Typography variant="h5" key={`Line-${i}`}>
                    {line}
                  </Typography>
                ))
              ) : (
                <AudioPlayer
                  className={classes.audioPlayer}
                  src={psalm.url}
                  variation="primary"
                  volume={false}
                  time="single"
                  key={i}
                />
              )}
            </div>
          ))}
      </Paper>
    </div>
  )
}

export default Psalms
