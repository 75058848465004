/* eslint-disable react/jsx-props-no-spreading */
import React from "react"

const styles = {
  marginTop: 8,
  height: 4,
  width: "100%",
  background:
    "linear-gradient(30deg, rgba(253,233,211,1) 0%, rgba(197,161,120,1) 50%, rgba(253,233,211,1) 100%)",
}

export default function GoldBar(props) {
  return <div style={styles} {...props} />
}
