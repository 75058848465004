import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import Paper from "@material-ui/core/Paper"
import { Typography, Button } from "@material-ui/core"
import NavBar from "../../components/plaque/navBar"
//
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
//
import { useLocalization } from "gatsby-theme-i18n"
import { firestore, useFirestoreDoc } from "gatsby-theme-firebase"
import Backdrop from "../../components/backdrop"
//Map stuff
import RoomIcon from "@material-ui/icons/Room"
import GoogleMapReact from "google-map-react"

const logo =
  "https://firebasestorage.googleapis.com/v0/b/ornechama-1a02f.appspot.com/o/public%2Fgold%20icons%2Fgeolocalisation%20icon.png?alt=media&token=96320c8f-9eaa-47fe-bb28-e234786d99dd"

const useStyles = makeStyles(theme => ({
  root: {
    fontFamily: "Lato",
    minHeight: "100vh",
    paddingBottom: theme.spacing(5),
  },
  surface: {
    padding: theme.spacing(2),
    margin: theme.spacing(2, 1),
  },
  centerContainer: {
    maxWidth: 500,
    marginLeft: "auto",
    marginRight: "auto",
  },
  btnDiv: {
    margin: theme.spacing(2),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}))

// let months = ['Janvier', 'Février','Mars','Avril','Mai', 'Juin','Juillet','Aout','Septembre','Octobre','Novembre','Décembre']

const Lieu = ({ plaqueId, userId, location }) => {
  const classes = useStyles()
  let { state: plaque } = location
  const { locale } = useLocalization()
  const [text, isLoadingText] = useFirestoreDoc(
    firestore.collection(`text/localized/${locale}`).doc("lieu")
  )
  if (isLoadingText || !text || !plaque) return <Backdrop />
  return (
    <div className={classes.root}>
      <NavBar
        plaqueId={plaqueId}
        text={text.navBar || "LIEU DE REPOS"}
        logo={logo}
      />
      <div className={classes.centerContainer}>
        <Paper className={classes.surface}>
          <Typography>{text.title || "Lieu de repos"}</Typography>
          <List>
            {plaque.nameOfCemetery && (
              <ListItem>
                <ListItemText secondary={text.cemeteryTitle || "Cimetière"}>
                  {plaque.nameOfCemetery}
                </ListItemText>
              </ListItem>
            )}
            {plaque.addressOfCemetery && (
              <ListItem>
                <ListItemText secondary={text.addressTitle || "Adresse"}>
                  {plaque.addressOfCemetery}
                </ListItemText>
              </ListItem>
            )}
            {plaque.tombLocation && (
              <ListItem>
                <ListItemText secondary={text.locationTitle || "Emplacement"}>
                  {plaque.tombLocation}
                </ListItemText>
              </ListItem>
            )}
          </List>
          {plaque.addressOfCemetery && plaque.addressOfCemeteryCoords && (
            <>
              <div style={{ height: "300px", width: "100%" }}>
                <GoogleMapReact
                  bootstrapURLKeys={{
                    key: "AIzaSyDqkiKpbEJT7Sx1H3LZdvJrvtWG0_OwOBI",
                  }}
                  defaultCenter={plaque.addressOfCemeteryCoords}
                  defaultZoom={15}
                >
                  <RoomIcon
                    fontSize="large"
                    lat={plaque.addressOfCemeteryCoords.lat}
                    lng={plaque.addressOfCemeteryCoords.lng}
                    color="primary"
                  />
                </GoogleMapReact>
              </div>
              <div className={classes.btnDiv}>
                <Button
                  color="primary"
                  target="_blank"
                  href={`https://www.google.com/maps/search/?api=1&query=${plaque.addressOfCemeteryCoords.lat},${plaque.addressOfCemeteryCoords.lng}`}
                >
                  {text.btns.openInMaps || "ouvert à google maps"}
                </Button>
              </div>
            </>
          )}
        </Paper>
      </div>
    </div>
  )
}

export default Lieu
