import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import { LocalizedRouter } from "gatsby-theme-i18n"
import PrivateRoute from "../services/privateRoute"
import NotFound from "./404"
// Plaque
import Plaque from "../templates/plaque/plaque"
import Bio from "../templates/plaque/bio"
import Lieu from "../templates/plaque/lieu"
import Prayers from "../templates/plaque/prayers"
import Psalmes from "../templates/plaque/psalmes"
import AlphaBeta from "../templates/plaque/psalmesAlphaBeta"
import Nechama from "../templates/plaque/psalmesNechama"
import Kaddish from "../templates/plaque/kaddish"
import Hachkava from "../templates/plaque/hachkava"
import Album from "../templates/plaque/album"
import Visitors from "../templates/plaque/visitors"
import Messages from "../templates/plaque/messages"
import LinksAndEvents from "../templates/plaque/linksAndEvents"
// App
import Dashboard from "../templates/app/dashboard"
import Cart from "../templates/app/cart"
import EditPlaque from "../templates/app/editPlaque"
import Admin from "../templates/app/admin"
import MsgTable from "../templates/app/msgTable"
import UserSettings from "../templates/app/user"
// components
import Footer from "../components/footer"


const useStyles = makeStyles(theme => ({
  root: {
    minHeight: "100vh",
    position: "relative",
  },
  footer: {
    position: "absolute",
    bottom: 0,
    width: "100%",
    height: theme.spacing(3),
  },
}))

const App = () => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <div className={classes.contentWrap}>
        <LocalizedRouter basePath="/app">
          <PrivateRoute path="/" component={Dashboard} />
          <PrivateRoute path="edit" component={EditPlaque} />
          <PrivateRoute path="cart" component={Cart} />
          <PrivateRoute path="messages" component={MsgTable} />
          <PrivateRoute path="admin" component={Admin} />
          <PrivateRoute path="settings" component={UserSettings} />
          {/* Storage V2 path */}
          <Plaque path="plaque/:plaqueId" />
          {/* Legacy path. Same component but wont make a fuss when the plaque comes from a V1 qr code */}
          <Plaque path="plaque/:plaqueId/:userId" />
          <Bio path="plaque/:plaqueId/bio" />
          <Lieu path="plaque/:plaqueId/locations" />
          <Prayers path="plaque/:plaqueId/prayers" />
          <Psalmes path="plaque/:plaqueId/psalmes" />
          <AlphaBeta path="plaque/:plaqueId/alphabeta" />
          <Nechama path="plaque/:plaqueId/nechama" />
          <Kaddish path="plaque/:plaqueId/kaddish" />
          <Hachkava path="plaque/:plaqueId/hachkava" />
          <Album path="plaque/:plaqueId/album" />
          <Visitors path="plaque/:plaqueId/visitors" />
          <Messages path="plaque/:plaqueId/messages" />
          <LinksAndEvents path="plaque/:plaqueId/linksAndEvents" />
          <NotFound default />
        </LocalizedRouter>
      </div>
      <Footer />
    </div>
  )
}

export default App
