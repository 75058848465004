/* eslint-disable react/no-array-index-key */
import React from "react"
// Layout
import { makeStyles, useTheme } from "@material-ui/core/styles"
import Input from "@material-ui/core/Input"
import InputLabel from "@material-ui/core/InputLabel"
import MenuItem from "@material-ui/core/MenuItem"
import FormControl from "@material-ui/core/FormControl"
import Select from "@material-ui/core/Select"
import Chip from "@material-ui/core/Chip"
import Grid from "@material-ui/core/Grid"
// Data
import { firestore } from "gatsby-theme-firebase"


const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  gridItem: {
    padding: theme.spacing(1),
  },
}))

let delayTimer
const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

function getStyles(name, value, theme) {
  return {
    fontWeight:
      value.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  }
}

export default function Traditions({ plaque, text }) {
  const [values, setValues] = React.useState({
    traditions: plaque.traditions || [],
    hebrewName: plaque.hebrewName || "",
    kaddishType: plaque.kaddishType || 0,
  })
  const handleInputChange = e => {
    e.persist()
    setValues({ ...values, [e.target.name]: e.target.value })
    clearTimeout(delayTimer)
    delayTimer = setTimeout(() => {
      firestore
        .collection("plaques")
        .doc(plaque._id)
        .update({
          [e.target.name]: e.target.value,
        })
    }, 1000)
  }
  // Select stuff
  const classes = useStyles()
  const theme = useTheme()

  const traditionsItems = text.plaqueEditorTraditionsItems || []
  const kaddishItems = text.plaqueEditorTraditionsKaddishTypes || []

  return (
    <>
      <Grid
        container
        alignItems="center"
        justify="space-evenly"
        direction="row"
      >
        <Grid className={classes.gridItem} item xs={12} sm={12} md={12} lg={12}>
          <FormControl className={classes.formControl} fullWidth>
            <InputLabel id="prayers-select-label">
              {text.plaqueEditorTraditionsSelectLabel}
            </InputLabel>
            <Select
              name="traditions"
              labelId="prayers-select-label"
              id="prayers-select"
              multiple
              value={values.traditions}
              onChange={handleInputChange}
              input={<Input id="select-multiple-chip-prayers" />}
              renderValue={selected => (
                <div className={classes.chips}>
                  {selected.map((value, i) => (
                    <Chip
                      key={`traditions-${i}`}
                      label={traditionsItems[value]}
                      className={classes.chip}
                    />
                  ))}
                </div>
              )}
              MenuProps={MenuProps}
            >
              {traditionsItems.map((name, i) => (
                <MenuItem
                  key={`traditionItems-${i}`}
                  value={i}
                  style={getStyles(name, values.traditions, theme)}
                >
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        {values.traditions.some(el => el === 1) && (
          <Grid
            className={classes.gridItem}
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
          >
            <FormControl className={classes.formControl} fullWidth>
              <InputLabel id="kaddish-select-label">
                {text.plaqueEditorTraditionsKaddishSelectLabel}
              </InputLabel>
              <Select
                name="kaddishType"
                labelId="kaddish-select-label"
                id="kaddish-select"
                value={values.kaddishType}
                onChange={handleInputChange}
                input={<Input id="select-multiple-chip-kaddish" />}
                MenuProps={MenuProps}
              >
                {kaddishItems.map((name, i) => (
                  <MenuItem key={`kaddishItems-${i}`} value={i}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        )}
      </Grid>
    </>
  )
}
