import React, { useState } from "react"
// Layout
import { FormControl, TextField, IconButton, Divider } from "@material-ui/core"
import SaveIcon from "@material-ui/icons/Save"
import DeleteIcon from "@material-ui/icons/Delete"
import AddCircleIcon from "@material-ui/icons/AddCircle"
// Data
import { firestore, useFirestoreQuery } from "gatsby-theme-firebase"
import Backdrop from "../backdrop"

function Event({ plaque, text, doc }) {
  const [values, setValues] = useState({
    content: doc.content ? doc.content : "",
    title: doc.title ? doc.title : "",
  })
  const handleInputChange = e => {
    e.persist()
    setValues({ ...values, [e.target.name]: e.target.value })
  }
  const handleForm = e => {
    e.preventDefault()
    firestore
      .collection(`plaques/${plaque._id}/events`)
      .doc(doc._id)
      .update(values)
  }
  const deleteDoc = () => {
    firestore.collection(`plaques/${plaque._id}/events`).doc(doc._id).delete()
  }
  return (
    <>
      <form align="right" onSubmit={handleForm}>
        <FormControl fullWidth margin="dense">
          <TextField
            required
            name="title"
            label={text.plaqueEditorEventsTitleLabel}
            value={values.title}
            onChange={handleInputChange}
          />
        </FormControl>
        <FormControl fullWidth margin="dense">
          <TextField
            required
            name="content"
            label={text.plaqueEditorEventsContentLabel}
            value={values.content}
            onChange={handleInputChange}
            multiline
          />
        </FormControl>
        <IconButton onClick={deleteDoc}>
          <DeleteIcon />
        </IconButton>
        <IconButton type="submit">
          <SaveIcon />
        </IconButton>
      </form>
    </>
  )
}

export default function Events({ plaque, text }) {
  const [events, isLoading] = useFirestoreQuery(
    firestore.collection(`plaques/${plaque._id}/events`).orderBy("time", "asc")
  )
  const add = () => {
    firestore.collection(`plaques/${plaque._id}/events`).add({
      title: "",
      content: "",
      time: new Date(),
    })
  }
  return (
    <div align="right">
      {events &&
        events.map((e) => (
          <>
            <Event plaque={plaque} text={text} doc={e} key={e._id} />
            <Divider />
          </>
        ))}
      <IconButton onClick={add}>
        <AddCircleIcon />
      </IconButton>
      {isLoading && <Backdrop />}
    </div>
  )
}
