import React from "react"
// Layout and Styling
import { makeStyles } from "@material-ui/core/styles"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import IconButton from "@material-ui/core/IconButton"
import MenuIcon from "@material-ui/icons/Menu"
import Typography from "@material-ui/core/Typography"
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer"
import MenuList from "./menuList"
import GoldBar from "../goldBar"

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  title: {
    flexGrow: 1,
    fontFamily: "Lato",
  },
  fullList: {
    width: "auto",
  },
  goldBar: {
    position: "relative",
    bottom: theme.spacing(1),
  },
}))

export default function NavBar({ location, text }) {
  const locText = location || 'Dashboard'
  const classes = useStyles()
  const [state, setState] = React.useState({
    drawerOpen: false,
  })
  const toggleDrawer = e => {
    if (e && e.type === "keydown" && (e.key === "Tab" || e.key === "Shift")) {
      return
    }
    setState({ ...state, drawerOpen: !state.drawerOpen })
  }

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar>
          <Typography className={classes.title} variant="h5" component="h5">
            {locText}
          </Typography>
          <IconButton
            edge="end"
            className={classes.menuButton}
            color="secondary"
            aria-label="menu"
            onClick={toggleDrawer}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
        <GoldBar className={classes.goldBar} />
      </AppBar>
      <SwipeableDrawer
        anchor="right"
        open={state.drawerOpen}
        onClose={toggleDrawer}
        onOpen={toggleDrawer}
        className={classes.drawer}
      >
        <MenuList text={text} location={location} />
      </SwipeableDrawer>
    </div>
  )
}
