import React, { useState } from "react"
import { makeStyles } from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import { useLocalization } from "gatsby-theme-i18n"
import { Button } from "@material-ui/core"
import { navigate } from "gatsby"
import Helmet from 'react-helmet'
// Data
import { firestore, useFirestoreDoc, useAuth } from "gatsby-theme-firebase"
// Icons
import CropFreeIcon from "@material-ui/icons/CropFree"
import ShareIcon from "@material-ui/icons/Share"
import Snackbar from "@material-ui/core/Snackbar"
import IconButton from "@material-ui/core/IconButton"
import CloseIcon from "@material-ui/icons/Close"
// Other comps
import GridBtn from "../../components/plaque/gridBtn"
import Backdrop from "../../components/backdrop"


const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(1),
    fontFamily: "Lato",
    color: theme.palette.primary.contrastText,
    minHeight: "100vh",
    paddingBottom: theme.spacing(5),
  },
  centerContainer: {
    maxWidth: 500,
    marginLeft: "auto",
    marginRight: "auto",
  },
  mediaCard: {
    backgroundColor: "rgb(0,0,0,0)",
    borderRadius: 10,
  },
  media: {
    maxWidth: "100%",
    maxHeight: "100%",
    objectFit: "cover",
    overflow: "hidden",
    borderRadius: 10,
    float: "left",
  },
  btnGridContainer: {
    marginTop: theme.spacing(2),
  },
  hebrewName: {
    [theme.breakpoints.down(335)]: {
      fontSize: theme.spacing(3),
    },
  },
  backBtn: {
    marginTop: theme.spacing(5),
  },
}))

// let months = ['Janvier', 'Février','Mars','Avril','Mai', 'Juin','Juillet','Aout','Septembre','Octobre','Novembre','Décembre']

const Plaque = ({ plaqueId }) => {
  const { isLoggedIn } = useAuth()
  const classes = useStyles()
  const { locale, defaultLang } = useLocalization()
  const [plaque, isLoading] = useFirestoreDoc(
    firestore.collection("plaques").doc(plaqueId)
  )
  const [traditions, isLoadingTrad] = useFirestoreDoc(
    firestore.collection(`text`).doc("traditions")
  )
  const [text, isLoadingText] = useFirestoreDoc(
    firestore.collection(`text/localized/${locale}`).doc("plaque")
  )
  const [openSnack, setOpenSnack] = useState(false)
  const copyToClipboard = () => {
    const { href } = window.location
    if (!navigator.clipboard) {
      console.error("No clipboard! Error!")
      return
    }
    navigator.clipboard.writeText(href).then(
      () => {
        // console.info("Async: Copying to clipboard was successful!")
      },
      err => {
        console.error("Async: Could not copy text: ", err)
      }
    )
  }
  const handleClickShare = () => {
    setOpenSnack(true)
    copyToClipboard();
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return
    }

    setOpenSnack(false)
  }
  if (
    isLoadingText ||
    isLoadingTrad ||
    isLoading ||
    !text ||
    !traditions ||
    !plaque
  )
    return <Backdrop />
  const descendenceText = `${
    plaque.gender === "male"
      ? (text.descendence ? text.descendence.son : "") || "Fils de"
      : (text.descendence ? text.descendence.daughter : "") || "Fille de"
  } ${plaque.motherName || ""} & ${plaque.fatherName || ""}`
  const death = plaque.death ? plaque.death.split("/") : ""
  const born = plaque.born ? plaque.born.split("/") : ""
  return (
    <div className={classes.root}>
      <Helmet>
        <title>{plaque.name}</title>
      </Helmet>
      <div className={classes.centerContainer}>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="flex-start"
          spacing={1}
        >
          <Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
            {plaque.imgs.main && (
              <img
                src={plaque.imgs.main}
                className={classes.media}
                loading="lazy"
                alt="The deceased in question"
              />
            )}
          </Grid>
          <Grid item xs={7} sm={7} md={7} lg={7} xl={7} align="right">
            <Typography className={classes.hebrewName} dir="rtl" variant="h4">
              {plaque.hebrewName}
            </Typography>
            <Typography variant="h5">{plaque.name}</Typography>
            <Typography variant="body1">{descendenceText}</Typography>
            <Typography
              variant="h6"
              gutterBottom
            >{`${born[2]}-${death[2]}`}</Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Button
              size="small"
              color="secondary"
              startIcon={<CropFreeIcon />}
              onClick={() => window.open(plaque.imgs.qr)}
            >
              QR
            </Button>
            <Button
              size="small"
              color="secondary"
              startIcon={<ShareIcon />}
              onClick={handleClickShare}
            >
              Partager
            </Button>
            <Snackbar
              anchorOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              open={openSnack}
              autoHideDuration={1000}
              onClose={handleClose}
              message="Share link copied!"
              action={
                <>
                  <IconButton
                    size="small"
                    aria-label="close"
                    color="inherit"
                    onClick={handleClose}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </>
              }
            />
          </Grid>
        </Grid>
        <Grid
          className={classes.btnGridContainer}
          container
          direction="row"
          justify="center"
          alignItems="center"
          spacing={1}
        >
          <GridBtn
            icon="https://firebasestorage.googleapis.com/v0/b/ornechama-1a02f.appspot.com/o/public%2Fgold%20icons%2Fa%20la%20memoire%20de%20icon.png?alt=media&token=26e45afb-a306-401b-8ac2-3fd34e409b4f"
            text={(text.btns ? text.btns.bio : "") || "BIOGRAPHIE"}
            to="bio"
            plaqueId={plaqueId}
            plaque={plaque}
          />
          <GridBtn
            icon="https://firebasestorage.googleapis.com/v0/b/ornechama-1a02f.appspot.com/o/public%2Fgold%20icons%2Fgeolocalisation%20icon.png?alt=media&token=96320c8f-9eaa-47fe-bb28-e234786d99dd"
            text={(text.btns ? text.btns.lieu : "") || "LIEU DE REPOS"}
            to="locations"
            plaqueId={plaqueId}
            plaque={plaque}
          />
                    <GridBtn
            icon="https://firebasestorage.googleapis.com/v0/b/ornechama-1a02f.appspot.com/o/public%2Fgold%20icons%2FMESSAGE%20ICON.png?alt=media&token=d0e18900-ceff-472b-aa5e-11c4e48856f0"
            text={(text.btns ? text.btns.messages : "") || "MESSAGES"}
            to="visitors"
            plaqueId={plaqueId}
            plaque={plaque}
          />
          <GridBtn
            icon="https://firebasestorage.googleapis.com/v0/b/ornechama-1a02f.appspot.com/o/public%2Fgold%20icons%2FALBUM%20ICON.png?alt=media&token=ca064ae2-34b8-4b2c-bcb7-876551338edc"
            text={(text.btns ? text.btns.album : "") || "ALBUM"}
            to="album"
            plaqueId={plaqueId}
            plaque={plaque}
          />
          <GridBtn
            icon="https://firebasestorage.googleapis.com/v0/b/ornechama-1a02f.appspot.com/o/public%2Fgold%20icons%2FLIVRE%20D'OR%20ICON.png?alt=media&token=31f99e5f-a6f6-4a41-b38d-7ce329cd4bf2"
            text={(text.btns ? text.btns.visitors : "") || "LIVRE D`OR"}
            to="messages"
            plaqueId={plaqueId}
            plaque={plaque}
          />
          <GridBtn
            icon="https://firebasestorage.googleapis.com/v0/b/ornechama-1a02f.appspot.com/o/public%2Fgold%20icons%2FEVENT%20ICON.png?alt=media&token=8bed579f-53a1-48f9-9833-3fb669ecef9a"
            text={(text.btns ? text.btns.events : "") || "ÉVÈNEMENTS ET LIENS"}
            to="linksAndEvents"
            plaqueId={plaqueId}
            plaque={plaque}
          />
          {plaque.traditions.some(e => e === 5) && (
            <GridBtn
              icon="https://firebasestorage.googleapis.com/v0/b/ornechama-1a02f.appspot.com/o/public%2Fgold%20icons%2Frites%20%26%20tradition%20icon.png?alt=media&token=a6b7129e-8559-4a64-92a5-f506bddf545a"
              text={(text.btns ? text.btns.prayers : "") || "PRIÈRES"}
              to="prayers"
              plaqueId={plaqueId}
              plaque={plaque}
              traditions={traditions}
            />
          )}
          {plaque.traditions.some(e => e === 4) && (
            <GridBtn
              icon="https://firebasestorage.googleapis.com/v0/b/ornechama-1a02f.appspot.com/o/public%2Fgold%20icons%2FPSAUME%20PRI%C3%88RE%20ICON.png?alt=media&token=bc93a140-8c18-4db9-a67d-469dff461b47"
              text={
                (text.btns ? text.btns.psaumes : "") || "PSAUMES ÌNTREGAL (119)"
              }
              to="psalmes"
              plaqueId={plaqueId}
              plaque={plaque}
              traditions={traditions}
            />
          )}
          {plaque.traditions.some(e => e === 3) && plaque.hebrewName && (
            <GridBtn
              icon="https://firebasestorage.googleapis.com/v0/b/ornechama-1a02f.appspot.com/o/public%2Fgold%20icons%2FPSAUME%20ALPHA%20BETA%20ICON.png?alt=media&token=ebe4a28b-3612-494a-b225-03f6d2653623"
              text={
                (text.btns ? text.btns.alphaBeta : "") ||
                "PSAUMES LETTRES (NOM)"
              }
              to="alphabeta"
              plaqueId={plaqueId}
              plaque={plaque}
              traditions={traditions}
            />
          )}
          {plaque.traditions.some(e => e === 0) && (
            <GridBtn
              icon="https://firebasestorage.googleapis.com/v0/b/ornechama-1a02f.appspot.com/o/public%2Fgold%20icons%2FPSAUME%20NECHAMA%20ICON.png?alt=media&token=eb41787d-40ac-44ba-9af2-eb337f771640"
              text={(text.btns ? text.btns.nechama : "") || "PSAUMES NECHAMA"}
              to="nechama"
              plaqueId={plaqueId}
              plaque={plaque}
              traditions={traditions}
            />
          )}
          {plaque.traditions.some(e => e === 1) && (
            <GridBtn
              icon="https://firebasestorage.googleapis.com/v0/b/ornechama-1a02f.appspot.com/o/public%2Fgold%20icons%2FKADDISH%20ICON.png?alt=media&token=5d19c162-90ad-4e1d-b91a-4f1f6b083a42"
              text={(text.btns ? text.btns.kaddish : "") || "KADDISH"}
              to="kaddish"
              plaqueId={plaqueId}
              plaque={plaque}
              traditions={traditions}
            />
          )}
          {plaque.traditions.some(e => e === 2) && plaque.gender && (
            <GridBtn
              icon="https://firebasestorage.googleapis.com/v0/b/ornechama-1a02f.appspot.com/o/public%2Fgold%20icons%2FHASHKAVA%20ICON.png?alt=media&token=32ce74e3-5f57-4c57-999c-9b76d84e6198"
              text={(text.btns ? text.btns.hachkava : "") || "HACHKAVA"}
              to="hachkava"
              plaqueId={plaqueId}
              plaque={plaque}
              traditions={traditions}
            />
          )}
        </Grid>
        {isLoggedIn && (
          <div className={classes.backBtn} align="center">
            <Button
              color="secondary"
              onClick={() => {
                navigate(`${locale === defaultLang ? "" : `/${locale}`}/app`)
              }}
            >
              {text.btns ? text.btns.backToDashboard : "Retour Accueil"}
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}

export default Plaque
