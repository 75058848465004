import React from "react"
import { navigate } from "gatsby"
// Layout & styles
import { makeStyles } from "@material-ui/core/styles"
import Card from "@material-ui/core/Card"
import CardActionArea from "@material-ui/core/CardActionArea"
import CardActions from "@material-ui/core/CardActions"
import CardContent from "@material-ui/core/CardContent"
import CardMedia from "@material-ui/core/CardMedia"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
// Icons
import EditIcon from "@material-ui/icons/Edit"
import CommentIcon from "@material-ui/icons/Comment"
// Other
import { useLocalization } from "gatsby-theme-i18n"
import Backdrop from "../backdrop"

const useStyles = makeStyles(() => ({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  name: {},
  pos: {
    marginBottom: 12,
  },
  cardActions: {
    float: "right",
  },
}))

export default function PlaqueCard({ data, text }) {
  const classes = useStyles()
  const { locale, defaultLang } = useLocalization()
  // const copyToClipboard = ()=>{
  //     let text = data.QRUrl
  //     if (!navigator.clipboard) {
  //         console.log('No clipboard! Error!');
  //         return;
  //     }
  //     navigator.clipboard.writeText(text).then(function() {
  //         console.log('Async: Copying to clipboard was successful!');
  //     }, function(err) {
  //         console.error('Async: Could not copy text: ', err);
  //     });
  // }
  if (!data.imgs) return <Backdrop />
  const death = data.death ? data.death.split("/") : ""
  const born = data.born ? data.born.split("/") : ""
  return (
    <Card className={classes.root}>
      <CardActionArea
        disabled={!data.ordered}
        onClick={() => {
          navigate(
            `${locale === defaultLang ? "" : `/${locale}`}/app/plaque/${
              data._id
            }`
          )
        }}
      >
        <CardMedia
          component="img"
          alt="Plaque Image"
          height="140"
          image={data.imgs.main ? data.imgs.main : data.imgs.qr}
        />
        <CardContent>
          <Typography
            className={classes.title}
            color="textSecondary"
            gutterBottom
            variant="h5"
            component="h2"
          >
            {data.ordered ? "" : text.plaqueCardTitle}
          </Typography>
          <Typography className={classes.name} variant="h6" component="h3">
            {data.name ? data.name : ""}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            {data.shortBio ? data.shortBio : ""}
          </Typography>
          <Typography
            variant="body1"
            color="textPrimary"
            component="h6"
            align="left"
          >
            {`${born[2] ? born[2] : ""}-${death[2] ? death[2] : ""}`}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions className={classes.cardActions}>
        <Button
          onClick={() => {
            navigate(
              `${locale === defaultLang ? "" : `/${locale}`}/app/edit/`,
              { state: { plaqueId: data._id } }
            )
          }}
          size="small"
          startIcon={<EditIcon />}
        >
          {text.plaqueCardEditBtn}
        </Button>
        <Button
          onClick={() => {
            navigate(
              `${locale === defaultLang ? "" : `/${locale}`}/app/messages/`,
              { state: { plaqueId: data._id } }
            )
          }}
          size="small"
          startIcon={<CommentIcon />}
        >
          {text.plaqueCardMessagesBtn}
        </Button>
        {/* <Button onClick={copyToClipboard} title='copy to clipboard' size="small" startIcon={<ShareIcon/>} disabled>Share</Button> */}
      </CardActions>
    </Card>
  )
}
