/* eslint-disable no-restricted-syntax */
/* eslint-disable no-await-in-loop */
import React, { useState } from "react"
import { makeStyles } from "@material-ui/core/styles"
import { Typography, Button, Paper, TextField } from "@material-ui/core"
import { navigate } from "gatsby"
import { useLocalization } from "gatsby-theme-i18n"
// Data
import { firestore, useFirestoreDoc, firebase } from "gatsby-theme-firebase"
import ImageUploader from "react-images-upload"
import Backdrop from "../../components/backdrop"
// Other
import NavBar from "../../components/plaque/navBar"

const logo =
  "https://firebasestorage.googleapis.com/v0/b/ornechama-1a02f.appspot.com/o/public%2Fgold%20icons%2FMESSAGE%20ICON.png?alt=media&token=d0e18900-ceff-472b-aa5e-11c4e48856f0"

const useStyles = makeStyles(theme => ({
  root: {
    fontFamily: "Lato",
    minHeight: "100vh",
    paddingBottom: theme.spacing(5),
  },
  surface: {
    padding: theme.spacing(2),
    margin: theme.spacing(2, 1),
  },
  button: {
    marginTop: theme.spacing(5),
    fontFamily: "lato",
    borderRadius: 20,
    background:
      "linear-gradient(50deg, rgba(253,233,211,1) 0%, rgba(197,161,120,1) 50%, rgba(253,233,211,1) 100%)",
  },
  gridItem: {
    padding: theme.spacing(6, 4),
  },
  centerContainer: {
    maxWidth: 500,
    marginLeft: "auto",
    marginRight: "auto",
  },
  textFields: {
    marginBottom: theme.spacing(1),
  },
}))

const Visitors = ({ plaqueId, location }) => {
  const plaque = location.state
  const classes = useStyles()
  const { locale, defaultLang } = useLocalization()
  const [text, isLoadingText] = useFirestoreDoc(
    firestore.collection(`text/localized/${locale}`).doc("visitors")
  )
  const [pictures, setPictures] = useState([])
  const [values, setValues] = useState({
    name: "",
    message: "",
    timestamp: `${new Date().getDate()}/${
      new Date().getMonth() + 1
    }/${new Date().getFullYear()}`,
    location: "",
  })
  const upload = async id => {
    const storageRef = firebase
      .storage()
      .ref(`/plaqueData/${plaqueId}/${plaque.owner}/messages/${id}`)
    const newPics = [...pictures]
    for (const pic of pictures) {
      const currentIndex = pictures.indexOf(pic)
      newPics.splice(currentIndex, 1)
      try {
        const ref = storageRef.child(pic.name)
        await ref.put(pic)
      } catch (ex) {
        console.error(ex)
      }
    }
    setPictures(newPics)
  }
  const sendMessage = async e => {
    e.preventDefault()
    try {
      const doc = await firestore
        .collection(`plaques/${plaqueId}/messages`)
        .add(values)
      await upload(doc.id)
      navigate(
        `${
          locale === defaultLang ? "" : `/${locale}`
        }/app/plaque/${plaqueId}/messages`,
        { state: plaque }
      )
    } catch (error) {
      console.error(error)
    }
  }
  const handleInputChange = e => {
    setValues({ ...values, [e.target.name]: e.target.value })
  }
  const onDrop = picture => {
    setPictures(picture)
  }

  // console.log(text, isLoadingText);
  if (isLoadingText || !text) return <Backdrop />
  return (
    <div className={classes.root}>
      <NavBar plaqueId={plaqueId} text={text.navbar.title} logo={logo} />
      <div className={classes.centerContainer}>
        <Paper className={classes.surface}>
          <Typography variant="h6" gutterBottom>
            {text.cardTitle}
          </Typography>
          <form className={classes.form} onSubmit={sendMessage}>
            <TextField
              className={classes.textFields}
              id="name"
              name="name"
              onChange={handleInputChange}
              label={text.nameField.label}
              type="text"
              helperText={text.nameField.helperText}
              variant="outlined"
              required
              fullWidth
            />
            <TextField
              className={classes.textFields}
              id="location"
              name="location"
              onChange={handleInputChange}
              label={text.locationField.label}
              type="text"
              variant="outlined"
              fullWidth
              helperText={text.locationField.helperText}
            />
            <TextField
              className={classes.textFields}
              id="message"
              name="message"
              onChange={handleInputChange}
              label={text.messageField.label}
              helperText={text.messageField.helperText}
              type="text"
              variant="outlined"
              multiline
              rows={10}
              required
              fullWidth
            />
            <Typography className={classes.textFields} variant="h6">
              {text.uploadTitle}
            </Typography>
            <ImageUploader
              onChange={onDrop}
              withPreview
              imgExtension={[".jpg", ".jpeg", ".png"]}
              pictures={pictures}
              singleImage
              buttonText={text.buttons.selectImg}
              label={text.uploadLabel}
            />
            <Button
              type="submit"
              className={classes.button}
              variant="contained"
              color="secondary"
            >
              {text.buttons.send}
            </Button>
          </form>
        </Paper>
      </div>
    </div>
  )
}

export default Visitors
