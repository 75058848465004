import React from "react"
//Layout and Styling
import { makeStyles } from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import CardActionArea from "@material-ui/core/CardActionArea"
import Typography from "@material-ui/core/Typography"
import { navigate } from "gatsby"
import { useLocalization } from "gatsby-theme-i18n"

const useStyles = makeStyles(theme => ({
  card: {
    background:
      "linear-gradient(50deg, rgba(253,233,211,1) 0%, rgba(197,161,120,1) 50%, rgba(253,233,211,1) 100%)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 15,
    [theme.breakpoints.up(425)]: {
      width: theme.spacing(16),
      height: theme.spacing(16),
    },
    [theme.breakpoints.down(425)]: {
      width: theme.spacing(15),
      height: theme.spacing(15),
    },
    [theme.breakpoints.down(400)]: {
      width: theme.spacing(14),
      height: theme.spacing(14),
    },
    [theme.breakpoints.down(375)]: {
      width: theme.spacing(13),
      height: theme.spacing(13),
    },
    [theme.breakpoints.down(350)]: {
      width: theme.spacing(12),
      height: theme.spacing(12),
    },
    [theme.breakpoints.down(325)]: {
      width: theme.spacing(12),
      height: theme.spacing(12),
    },
  },
  cardBtn: {
    backgroundColor: theme.palette.primary.light,
    width: "95%",
    height: "95%",
    borderRadius: 15,
  },
  cardContent: {
    padding: theme.spacing(1, 0.5, 0.5, 0.5),
  },
  cardText: {
    color: theme.palette.primary.contrastText,
    fontSize: theme.spacing(1.7),
    fontWeight: "bold",
    [theme.breakpoints.down(425)]: {
      fontSize: theme.spacing(1.5),
    },
    [theme.breakpoints.down(350)]: {
      fontSize: theme.spacing(1.3),
    },
    [theme.breakpoints.down(325)]: {
      fontSize: theme.spacing(1.3),
    },
  },
  img: {
    [theme.breakpoints.up(425)]: {
      height: theme.spacing(8),
    },
    [theme.breakpoints.down(425)]: {
      height: theme.spacing(7),
    },
    [theme.breakpoints.down(400)]: {
      height: theme.spacing(6),
    },
    [theme.breakpoints.down(375)]: {
      height: theme.spacing(5),
    },
    [theme.breakpoints.down(350)]: {
      height: theme.spacing(4.5),
    },
    [theme.breakpoints.down(325)]: {
      height: theme.spacing(3.5),
    },
  },
}))

export default function GridBtn({
  icon,
  text,
  userId,
  plaqueId,
  to,
  plaque,
  traditions,
}) {
  const classes = useStyles()
  const { locale, defaultLang } = useLocalization()
  const goTo = () => {
    if (traditions) plaque = { plaque, traditions }
    navigate(
      (locale === defaultLang ? "" : "/" + locale) +
        "/app/plaque/" +
        plaqueId +
        "/" +
        to,
      { state: plaque }
    )
  }
  return (
    <Grid item xs={4} sm={4} md={4} lg={4} xl={4} align="center">
      <Card className={classes.card}>
        <CardActionArea className={classes.cardBtn} onClick={() => goTo()}>
          <CardContent align="center" className={classes.cardContent}>
            <img alt="" className={classes.img} src={icon} loading="lazy" />
            <Typography className={classes.cardText} variant="h6" component="p">
              {text}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </Grid>
  )
}
