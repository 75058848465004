import React, { useState } from "react"
// Layout
import { FormControl, TextField } from "@material-ui/core"
// Data
import { firestore } from "gatsby-theme-firebase"
// Maps
import GooglePlacesAutocomplete, {
  getLatLng,
  geocodeByAddress,
} from "react-google-places-autocomplete"


let delayTimer

export default function Location({ plaque, text }) {
  const [values, setValues] = useState({
    nameOfCemetery: plaque.nameOfCemetery ? plaque.nameOfCemetery : "",
    addressOfCemetery: plaque.addressOfCemetery ? plaque.addressOfCemetery : "",
    tombLocation: plaque.tombLocation ? plaque.tombLocation : "",
  })
  // const [addressOfCemetery, setAddress] = useState(plaque.addressOfCemetery?plaque.addressOfCemetery:'')
  const handleInputChange = e => {
    e.persist()
    setValues({ ...values, [e.target.name]: e.target.value })
    clearTimeout(delayTimer)
    delayTimer = setTimeout(() => {
      firestore
        .collection("plaques")
        .doc(plaque._id)
        .update({
          [e.target.name]: e.target.value,
        })
    }, 1000)
  }
  // Maps stuff
  return (
    <>
      <FormControl fullWidth margin="dense">
        <TextField
          name="nameOfCemetery"
          label={text.plaqueEditorLocation1}
          value={values.nameOfCemetery}
          onChange={handleInputChange}
        />
      </FormControl>
      <FormControl fullWidth margin="dense">
        {/* <TextField
                    name='addressOfCemetery'
                    label={text.plaqueEditorLocation2}
                    value={values.addressOfCemetery}
                    onChange={handleInputChange}
                /> */}
        <GooglePlacesAutocomplete
          apiKey="AIzaSyDqkiKpbEJT7Sx1H3LZdvJrvtWG0_OwOBI"
          selectProps={{
            onChange: async e => {
              if (e) {
                try {
                  const geo = await geocodeByAddress(e.label)
                  const coords = await getLatLng(geo[0])
                  firestore.collection("plaques").doc(plaque._id).update({
                    addressOfCemetery: geo[0].formatted_address,
                    addressOfCemeteryCoords: coords,
                  })
                } catch (ex) {
                  // eslint-disable-next-line no-console
                  console.error(ex)
                }
              } else {
                firestore.collection("plaques").doc(plaque._id).update({
                  addressOfCemetery: "",
                  addressOfCemeteryCoords: {},
                })
              }
            },
            onInputChange: e =>
              setTimeout(setValues({ ...values, addressOfCemetery: e }), 300),
            isClearable: true,
            placeholder: text.plaqueEditorLocation2 || "Tapez l'adresse",
            inputValue: values.addressOfCemetery,
          }}
        />
      </FormControl>
      <FormControl fullWidth margin="dense">
        <TextField
          name="tombLocation"
          label={text.plaqueEditorLocation3}
          placeholder={text.plaqueEditorLocation3Placeholder}
          value={values.tombLocation}
          onChange={handleInputChange}
        />
      </FormControl>
    </>
  )
}
