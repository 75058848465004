import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import { Grid, Typography } from "@material-ui/core"
import NavBar from "../../components/plaque/navBar"
import Msg from "../../components/plaque/messageCard"
//Data
import { useLocalization } from "gatsby-theme-i18n"
import {
  firestore,
  useFirestoreQuery,
  useFirestoreDoc,
} from "gatsby-theme-firebase"
import Backdrop from "../../components/backdrop"

const logo =
  "https://firebasestorage.googleapis.com/v0/b/ornechama-1a02f.appspot.com/o/public%2Fgold%20icons%2FLIVRE%20D'OR%20ICON.png?alt=media&token=31f99e5f-a6f6-4a41-b38d-7ce329cd4bf2"

const useStyles = makeStyles(theme => ({
  root: {
    fontFamily: "Lato",
    minHeight: "100vh",
    paddingBottom: theme.spacing(5),
  },
  surface: {
    padding: theme.spacing(2),
    margin: theme.spacing(2, 1),
  },
  grid: {
    padding: theme.spacing(2, 0.5),
  },
  centerContainer: {
    maxWidth: 500,
    marginLeft: "auto",
    marginRight: "auto",
  },
}))

const Messages = ({ plaqueId, location }) => {
  const plaque = location.state
  const classes = useStyles()
  const [msg, isLoading] = useFirestoreQuery(
    firestore
      .collection(`plaques/${plaqueId}/messages`)
      .orderBy("timestamp", "desc")
  )
  //Text stuff
  const { locale } = useLocalization()
  const [text, isLoadingText] = useFirestoreDoc(
    firestore.collection(`text/localized/${locale}`).doc("messages")
  )
  if (isLoading || isLoadingText || !text) return <Backdrop />
  return (
    <div className={classes.root}>
      <NavBar
        plaqueId={plaqueId}
        text={text.navBar || "LIVRE D`OR"}
        logo={logo}
      />
      <div className={classes.centerContainer}>
        <Grid
          container
          justify="center"
          alignItems="flex-start"
          className={classes.grid}
        >
          {msg.length > 0 &&
            msg.map(m => (
              <Msg
                msg={m}
                plaqueId={plaqueId}
                userId={plaque.owner}
                key={m._id}
              />
            ))}
          {msg.length === 0 && (
            <Typography>
              {text.noMsg || "Désolé, pas encore de messages"}
            </Typography>
          )}
        </Grid>
      </div>
    </div>
  )
}

export default Messages
