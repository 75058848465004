/* eslint-disable react/jsx-props-no-spreading */
import React, { forwardRef } from "react"
// Layout
import { makeStyles } from "@material-ui/core/styles"
import { Typography } from "@material-ui/core/"
// Data feed and Misc.
import { navigate } from "gatsby"
import { firestore, useFirestoreQuery } from "gatsby-theme-firebase"
// Table
import MaterialTable from "material-table"
import AddBox from "@material-ui/icons/AddBox"
import ArrowDownward from "@material-ui/icons/ArrowDownward"
import Check from "@material-ui/icons/Check"
import ChevronLeft from "@material-ui/icons/ChevronLeft"
import ChevronRight from "@material-ui/icons/ChevronRight"
import Clear from "@material-ui/icons/Clear"
import DeleteOutline from "@material-ui/icons/DeleteOutline"
import Edit from "@material-ui/icons/Edit"
import FilterList from "@material-ui/icons/FilterList"
import FirstPage from "@material-ui/icons/FirstPage"
import LastPage from "@material-ui/icons/LastPage"
import Remove from "@material-ui/icons/Remove"
import SaveAlt from "@material-ui/icons/SaveAlt"
import Search from "@material-ui/icons/Search"
import ViewColumn from "@material-ui/icons/ViewColumn"
import CropFreeIcon from "@material-ui/icons/CropFree"
import MenuBookIcon from "@material-ui/icons/MenuBook"
import Backdrop from "../backdrop"
// Other
import AdminUtils from './adminUtils'


const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
}

const useStyles = makeStyles(theme => ({
  root: {
    flex: 1,
    minHeight: "100vh",
    paddingBottom: theme.spacing(2),
  }
}))

const AdminTable = () => {
  const classes = useStyles()
  const [users, isLoadingUsers] = useFirestoreQuery(
    firestore.collection("users")
  )
  if (isLoadingUsers) return <Backdrop />
  return (
    <div className={classes.root}>
      <AdminUtils />
      <MaterialTable
        title="Users"
        style={{
          width: "95%",
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: "5%",
        }}
        icons={tableIcons}
        data={users}
        columns={[
          { title: "Name", field: "name" },
          { title: "UID", field: "_id"},
          { title: "Email", field: "email" },
          { title: "Phone", field: "phone" },
          { title: "Stripe Customer", field: "stripeCustomerId" },
        ]}
        detailPanel={rowData => <PlaqueTable user={rowData} />}
      />
    </div>
  )
}

export default AdminTable

const PlaqueTable = ({ user }) => {
  // console.log(user._id)
  const [plaques, isLoadingPlaques] = useFirestoreQuery(
    firestore.collection(`plaques`).where("owner", "==", user._id)
  )
  // console.log(plaques)
  if (isLoadingPlaques) return <Backdrop />
  if (plaques.length === 0)
    return (
      <Typography align="center" gutterBottom>
        User has no plaques
      </Typography>
    )
  return (
    <div>
      <MaterialTable
        title="Plaques"
        style={{
          width: "100%",
          marginBottom: "2%",
          marginTop: "2%",
        }}
        icons={tableIcons}
        data={plaques}
        columns={[
          { title: "Name", field: "name" },
          { title: "Ordered", field: "ordered", defaultGroupOrder: 0 },
          { title: "Birth", field: "born" },
          { title: "Death", field: "death" },
        ]}
        actions={[
          {
            icon: () => <Edit />,
            tooltip: "Edit plaque",
            onClick: (event, rowData) => {
              const state = {
                plaqueId: rowData._id,
              }
              navigate("/app/edit", { state })
            },
          },
          {
            icon: () => <MenuBookIcon />,
            tooltip: "View plaque",
            onClick: (event, rowData) => navigate(`/app/plaque/${rowData._id}`),
          },
          {
            icon: () => <CropFreeIcon />,
            tooltip: "QR code",
            onClick: (event, rowData) => window.open(rowData.imgs.qr),
          },
        ]}
      />
    </div>
  )
}
