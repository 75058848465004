/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import React, { useState } from "react"
import {
  Button,
  Grid,
  Modal,
  Paper,
  TextField,
  Typography,
  IconButton,
} from "@material-ui/core"
// Icons & Styling
import CloseIcon from "@material-ui/icons/Close"
import { makeStyles } from "@material-ui/core/styles"
import { useForm, Controller } from "react-hook-form"
import { useAuth } from "gatsby-theme-firebase"
import Backdrop from "../backdrop"

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(1),
  },
  surface: {
    backgroundColor: theme.palette.background.paper,
    minWidth: 450,
    maxWidth: "80%",
    marginRight: "auto",
    marginLeft: "auto",
    marginTop: theme.spacing(5),
    padding: theme.spacing(1),
  },
  close: {
    marginLeft: "auto",
    display: "block",
  },
}))

export default function AdminUtils() {
  const classes = useStyles()
  const [modal, setModal] = useState(false)
  const handleOpen = () => {
    setModal(true)
  }
  const handleClose = () => {
    setModal(false)
  }

  return (
    <div className={classes.root}>
      <Grid container direction="row" justify="center" alignItems="center">
        <Grid item>
          <Button variant="text" onClick={handleOpen}>
            Utilities
          </Button>
        </Grid>
      </Grid>
      <Modal
        open={modal}
        onClose={handleClose}
        aria-labelledby="admin-utils-modal"
        aria-describedby="exposes admin interface for managing plaques"
      >
        <Paper className={classes.surface}>
          <section>
            <IconButton onClick={handleClose} className={classes.close}>
              <CloseIcon />
            </IconButton>
          </section>
          <section>
            <CreatePlaque />
          </section>
        </Paper>
      </Modal>
    </div>
  )
}

const CreatePlaque = () => {
  const classes = useStyles()
  const { profile } = useAuth()
  const [res, setRes] = useState({
    loading: false,
    text: "",
  })
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm()
  // eslint-disable-next-line no-console
  const createPlaque = async data => {
    // 1.get user token Id to verify Id
    // 2. sets a req and returns success or failure
    const id = await profile.getIdToken(true)
    const payload = {
      ...data,
      auth: id,
    }
    setRes({ ...res, loading: true })
    const req = await fetch(
      "https://us-central1-ornechama-1a02f.cloudfunctions.net/createV2Plaque",
      {
        method: "POST",
        mode: "cors",
        body: JSON.stringify(payload),
      }
    )
    const text = await req.json()
    setRes({ text: text.text, loading: false })
    // If you ever run into a mem leak, here's a culprit:
    setTimeout(() => {
      setRes({ ...res, text: "" })
    }, 10000)
  }
  return (
    <form onSubmit={handleSubmit(createPlaque)}>
      <Typography align="center" variant="body1">
        Create user
      </Typography>
      <Controller
        name="user"
        control={control}
        rules={{ required: true }}
        render={({ field: { onChange, onBlur, ref } }) => (
          <TextField
            fullWidth
            label="User's UID"
            helperText="Creates a plaque and attatch it to a certain user. You can get it on the main admin table."
            onChange={onChange}
            inputRef={ref}
            onBlur={onBlur}
          />
        )}
      />
      <Button
        size="large"
        className={classes.close}
        type="submit"
        variant="outlined"
      >
        Create
      </Button>
      {res.loading && <Backdrop />}
      {res.text && <Typography align="center">{res.text}</Typography>}
    </form>
  )
}
