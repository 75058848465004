import React from "react"
// Layout
import { makeStyles } from "@material-ui/core/styles"
import { useLocalization } from "gatsby-theme-i18n"
import { firestore, useFirestoreDoc } from "gatsby-theme-firebase"
import NavBar from "../../components/app/navBar"
import AdminTable from "../../components/app/adminTable"
// Data feed and Misc.
import Backdrop from "../../components/backdrop"


const useStyles = makeStyles(theme => ({
  root: {
    flex: 1,
    minHeight: "100vh",
    paddingBottom: theme.spacing(5),
  },
}))

const Admin = ({ user }) => {
  const classes = useStyles()
  // Text
  const { locale } = useLocalization()
  const [text, isLoading] = useFirestoreDoc(
    firestore.collection("text").doc(locale)
  )
  if (isLoading || !text) return <Backdrop />
  return (
    <div className={classes.root}>
      <NavBar text={text} location="Admin" />
      <AdminTable text={text} user={user} />
    </div>
  )
}

export default Admin
