/* eslint-disable no-restricted-syntax */
/* eslint-disable no-await-in-loop */
import React, { useState, useEffect, useRef } from "react"
import { makeStyles } from "@material-ui/core/styles"
import {
  Typography,
  Grid,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
} from "@material-ui/core"
// Data
import { firebase } from "gatsby-theme-firebase"


const useStyles = makeStyles(theme => ({
  root: {
    fontFamily: "Lato",
    margin: theme.spacing(1),
  },
  surface: {
    padding: theme.spacing(2),
    margin: theme.spacing(2, 1),
  },
  grid: {
    marginTop: theme.spacing(2),
  },
  card: {
    margin: theme.spacing(1),
  },
  media: {
    maxHeight: "30vh",
    objectFit: "scale-down",
    overflow: "hidden",
  },
}))

const Msg = ({ userId, plaqueId, msg }) => {
  const storageRef = useRef(
    firebase
      .storage()
      .ref(`plaqueData/${plaqueId}/${userId}/messages/${msg._id}`)
  )
  const classes = useStyles()
  const [pic, setPic] = useState([])
  useEffect(() => {
    ;(async () => {
      // eslint-disable-next-line no-shadow
      const pic = (await storageRef.current.listAll()).items
      for (const item of pic) {
        item.download = await item.getDownloadURL()
      }
      setPic(pic)
    })()
  }, [])
  if (pic[0])
    return (
      <Grid className={classes.root} xs={12} sm={12} md={12} item>
        <Card>
          <CardActionArea
            onClick={() => {
              window.open(pic[0].download, "_blank")
            }}
          >
            <CardMedia
              component="img"
              image={pic[0].download}
              className={classes.media}
            />
            <CardContent>
              <Typography variant="subtitle1">{msg.name}</Typography>
              <Typography variant="subtitle2">{msg.Location}</Typography>
              <Typography variant="caption">{msg.timestamp}</Typography>
              <Typography align="justify">{msg.message}</Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </Grid>
    )
  return (
    <Grid className={classes.root} xs={12} sm={12} md={12} item>
      <Card>
        <CardContent>
          <Typography variant="subtitle1">{msg.name}</Typography>
          <Typography variant="subtitle2">{msg.location}</Typography>
          <Typography variant="caption">{msg.timestamp}</Typography>
          <Typography align="justify" variant="body1">
            {msg.message}
          </Typography>
        </CardContent>
      </Card>
    </Grid>
  )
}

export default Msg
