import React from "react"
// Layout
import ListItem from "@material-ui/core/ListItem"
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction"
import ListItemText from "@material-ui/core/ListItemText"
import IconButton from "@material-ui/core/IconButton"
import Close from "@material-ui/icons/Close"
import { Divider } from "@material-ui/core"
// Data
import { firestore } from "gatsby-theme-firebase"


export default function CartItem({ data, user, last }) {
  const remove = async () => {
    // Do some removy shit
    await firestore
      .collection(`users/${user.uid}/cart`)
      .doc(data.cartItemId)
      .delete()
  }

  return (
    <>
      <ListItem role={undefined} dense>
        <ListItemText
          primary={`${data.type} ${data.name}`}
          secondary={`€${data.price}`}
        />
        <ListItemSecondaryAction>
          <IconButton edge="end" aria-label="remove" onClick={remove}>
            <Close />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
      {!last && <Divider />}
    </>
  )
}
