import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import Paper from "@material-ui/core/Paper"
import { Typography } from "@material-ui/core"
import NavBar from "../../components/plaque/navBar"
import { useLocalization } from "gatsby-theme-i18n"
import { firestore, useFirestoreDoc } from "gatsby-theme-firebase"
import Backdrop from "../../components/backdrop"

const logo =
  "https://firebasestorage.googleapis.com/v0/b/ornechama-1a02f.appspot.com/o/public%2Fgold%20icons%2Fa%20la%20memoire%20de%20icon.png?alt=media&token=26e45afb-a306-401b-8ac2-3fd34e409b4f"

const useStyles = makeStyles(theme => ({
  root: {
    fontFamily: "Lato",
    minHeight: "100vh",
    paddingBottom: theme.spacing(5),
  },
  centerContainer: {
    maxWidth: 500,
    marginLeft: "auto",
    marginRight: "auto",
  },
  surface: {
    padding: theme.spacing(2),
    margin: theme.spacing(2, 1),
  },
}))

// let months = ['Janvier', 'Février','Mars','Avril','Mai', 'Juin','Juillet','Aout','Septembre','Octobre','Novembre','Décembre']

const Bio = ({ plaqueId, userId, location }) => {
  const classes = useStyles()
  let { state: plaque } = location
  const { locale } = useLocalization()
  const [text, isLoadingText] = useFirestoreDoc(
    firestore.collection(`text/localized/${locale}`).doc("bio")
  )
  if (isLoadingText || !text) return <Backdrop />
  return (
    <div className={classes.root}>
      <NavBar
        plaqueId={plaqueId}
        text={text.navBar || "BIOGRAPHIE"}
        logo={logo}
      />
      <div className={classes.centerContainer}>
        <Paper className={classes.surface}>
          <Typography variant="h6" gutterBottom>
            {text.shortTitle || "About"} {plaque.name}
          </Typography>
          {plaque.shortDescription &&
            plaque.shortDescription.split("\n").map((p, i) =>
              p ? (
                <Typography key={i} variant="body1" align="justify">
                  {p}
                </Typography>
              ) : (
                <br />
              )
            )}
        </Paper>
        <Paper className={classes.surface}>
          <Typography variant="h6" gutterBottom>
            {text.bioTitle || "Life & Biography"}
          </Typography>
          {plaque.biography &&
            plaque.biography.split("\n").map((p, i) =>
              p ? (
                <Typography key={i} variant="body1" align="justify">
                  {p}
                </Typography>
              ) : (
                <br />
              )
            )}
        </Paper>
      </div>
    </div>
  )
}

export default Bio
