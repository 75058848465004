import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import NavBar from "../../components/plaque/navBar"
import Psalms from "../../components/plaque/psalms"
//text
import { firestore, useFirestoreDoc } from "gatsby-theme-firebase"
import { useLocalization } from "gatsby-theme-i18n"
import Backdrop from "../../components/backdrop"
const logo =
  "https://firebasestorage.googleapis.com/v0/b/ornechama-1a02f.appspot.com/o/public%2Fgold%20icons%2FPSAUME%20PRI%C3%88RE%20ICON.png?alt=media&token=bc93a140-8c18-4db9-a67d-469dff461b47"

const useStyles = makeStyles(theme => ({
  root: {
    fontFamily: "Lato",
    minHeight: "100vh",
    paddingBottom: theme.spacing(5),
  },
  surface: {
    padding: theme.spacing(2),
    margin: theme.spacing(2, 1),
  },
  controlSurface: {
    padding: theme.spacing(1),
  },
  centerContainer: {
    maxWidth: 500,
    marginLeft: "auto",
    marginRight: "auto",
  },
}))

// let months = ['Janvier', 'Février','Mars','Avril','Mai', 'Juin','Juillet','Aout','Septembre','Octobre','Novembre','Décembre']

const Psalmes = ({ plaqueId, userId, location }) => {
  const classes = useStyles()
  const {
    state: { traditions },
  } = location
  //Text stuff
  const { locale } = useLocalization()
  const [text, isLoadingText] = useFirestoreDoc(
    firestore.collection(`text/localized/${locale}`).doc("psalms")
  )
  if (isLoadingText || !text) return <Backdrop />
  return (
    <div className={classes.root}>
      <NavBar
        plaqueId={plaqueId}
        text={text.NavBar || "PSAUMES ÌNTREGAL (119)"}
        logo={logo}
      />
      <div className={classes.centerContainer}>
        <Psalms source={traditions.psalms} />
      </div>
    </div>
  )
}

export default Psalmes
